import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import About from "../views/pages/about"
import Business from '../views/pages/business'
import Programme from '../views/pages/programme'
import Responsibility from '../views/pages/responsibility'
import Party from '../views/pages/party'
import Contact from '../views/pages/contact'
import Industry from '../views/pages/industry'
import PartyDetail from '../views/pages/party-detail'
import NewDetail from '../views/pages/new-detail'
import New from '../views/pages/new'
import Ceshi from '../views/pages/ceshi'
import Books from '../views/pages/books'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path:'/ceshi',
    name:'ceshi',
    component:Ceshi
  },
  {
    path:'/Books',
    name:'Books',
    component:Books
  },
  {
    path:'/NewsListBM/Detail/',
    name:'newDetail',
    component:NewDetail
  },
  {
    path:'/NewsListBM',
    name:'new',
    component:New
  },
  {
    path:'/partyDetail',
    name:'partyDetail',
    component:PartyDetail
  },
  {
    path: '/ZJBM',
    name: 'about',
    component: About
  },
  {
    path: '/FWWB',
    name: 'business',
    component: Business
  },
  {
    path: '/programme',
    name: 'programme',
    component: Programme
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    component: Responsibility
  },
  {
    path: '/party',
    name: 'party',
    component: Party
  },{
    path: '/LXWM',
    name: 'contact',
    component: Contact
  },
  {
    path:'/industry',
    name:'industry',
    component:Industry
  },{
      path: '*',
      component: () => import('@/views/404'),
      name: '404',
      meta: { title: '404未找到' }
    }
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode:'history',
  routes,
  
})

export default router
