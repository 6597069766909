<template>
    <div class="about">
        <topBtn ref="topBtn"></topBtn>
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/about_banner.jpg">
        </div>
        <div ref="zzz"></div>
        <div class="about_nav">
            <div class="all_box">
                <ul class="about_nav_ul">
                    <li @click="clickNav(1)">企业概况</li>
                    <li @click="clickNav(2)">企业文化</li>
                    <li @click="clickNav(3)">公司荣誉</li>
                    <li @click="clickNav(4)">组织架构</li>
                    <li @click="clickNav(5)">合作伙伴</li>
                    <!-- <li @click="clickNav(1)">邦芒人力简介</li>
                    <li @click="clickNav(2)">使命愿景&企业文化</li>
                    <li @click="clickNav(3)">公司荣誉图集</li>
                    <li @click="clickNav(4)">业务架构体系图</li>
                    <li @click="clickNav(5)">邦芒合作客户图集</li> -->
                </ul>
            </div>
        </div>
        <!-- 邦芒简介 -->
        <div class="about_about" id="about">
            <div class="all_box">
                <div class="all_title">
                    <h3>邦芒简介</h3>
                    <div class="henxian"></div>
                </div>
                <div class="about_text">
                    <p>邦芒人力，创建于2007年，总部位于上海，主营服务外包、人事代理、人力资源SaaS服务、企业福利平台、产业园区运营、招商引资、财税代理、产业投资等多航道业务，并积极探索互联网直聘平台、家居服务一站式平台等创新领域，以业务协作、资源交互的协同增效模式，打造一站式全产业链型企业。 </p>
                    <p> {{ currentYear }}载耕耘，公司已实现全国核心市场布局，同时陆续在香港和美国等地设立公司，目前全球直营分公司已有228家，服务雇员超100万人。
                    </p>
                    <p>未来，邦芒将继续坚持以技术为驱动，以客户为中心，依托多产业事业集群服务平台、服务全球的骨干网络、行业资深顾问专家库和全域人才数据库，提供全业态、全周期、数字化的“人力资源一站式解决方案”。“大智兴邦，大品传芒”，邦芒人力将持续为客户和雇员创造价值，并积极为推进稳就业、保民生不断努力！</p>
                </div>
            </div>
        </div>
        <!-- 使命与愿景 -->
        <div class="about_sm">
            <div class="all_box">
                <div class="all_title">
                    <h3>使命与愿景</h3>
                    <div class="henxian"></div>
                </div>
                <div class="fbt">面向中小微企业，提供积极的，完善的，可持续的人力资源生态服务！</div>
                <div class="about_sm_list">
                    <div class="about_sm_item">
                        <img src="@/assets/img/sm01.png">
                        <h3>品质与服务</h3>
                        <p>邦芒的进步取决于产品、服务、技术及响应速度。</p>
                    </div>
                    <div class="about_sm_item" style="margin-top: 1px;">
                        <img src="@/assets/img/sm02.png" style="width: 51px;">
                        <h3>全程贴心 </h3>
                        <p>我们视人力资源一流服务为一次完美旅程，这里只有终点，没有起点，我们将持续不断地提升服务的标准和目标。</p>
                    </div>
                    <div class="about_sm_item">
                        <img src="@/assets/img/sm03.png">
                        <h3>客户为本</h3>
                        <p>以客户导向来完善产品、服务、技术及团队再造。</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 企业文化 -->
        <div class="about_qywh" id="qywh">
            <div class="all_box">
                <div class="all_title">
                    <h3>企业文化</h3>
                    <div class="henxian"></div>
                </div>
                <div class="about_qywh_body">

                    <el-tabs v-model="activeName">
                        <el-tab-pane label="重人才培养 崇创新多元" name="first">
                            <div class="about_qywh_item">
                                <div class="about_qywh_item_left">
                                    <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/fe1133d3243791580028658622/TCgjdzo0NbcA.mp4" controls="controls"></video>
                                    <!-- <video src="@/assets/video/video1.mp4" controls="controls"></video> -->
                                </div>
                                <div class="about_qywh_item_right">
                                    <div class="about_qywh_text">
                                        邦芒高度重视人才的培养及发展，打造“青芒计划”管培生培养方案，每年从高校应届毕业生中甄选百余名“高潜质、高素质、高认同”的优秀人才，基于“从实践中成长，从挑战中蜕变”的理念，通过定制化培养模式，打造年轻化、多元化的未来领导者，孕育创新共享的企业文化。
                                    </div>
                                    <div class="about_qywh_btn">
                                        <a href="http://qm.50bm.com/" target="_blank">
                                            <p>了解详情</p>
                                            <img src="@/assets/img/more_off.png" class="img_off">
                                            <img src="@/assets/img/more_on.png" class="img_on">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="激发活力，开放协同" name="second">
                            <div class="about_qywh_item">
                                <div class="about_qywh_item_left">
                                    <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/3f171291243791580029114677/u565F8ri9AcA.mp4" controls="controls"></video>
                                    <!-- <video src="@/assets/video/video2.mp4" controls="controls"></video> -->
                                </div>
                                <div class="about_qywh_item_right">
                                    <div class="about_qywh_text">
                                        邦芒推崇“健康生活，快乐工作”的员工关怀理念，每年定期组织开展“健康月”主题活动，号召全员参与创意型团体户外健身项目，激发组织和个人活力，促进团队深度交流和协同共享，帮助员工和企业共同成长。
                                    </div>
                                    <!-- <div class="about_qywh_btn">
                                        <p>了解详情</p>
                                        <img src="@/assets/img/more_off.png" class="img_off">
                                        <img src="@/assets/img/more_on.png" class="img_on">
                                    </div> -->
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="逐梦不止，兴邦传芒" name="third">
                            <div class="about_qywh_item">
                                <div class="about_qywh_item_left">
                                    <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/0089793e243791580028776749/pAK8LpP1u0oA.mp4" controls="controls"></video>
                                    <!-- <video src="@/assets/video/video3.mp4" controls="controls"></video> -->
                                </div>
                                <div class="about_qywh_item_right">
                                    <div class="about_qywh_text">
                                        邦芒坚持以客户为导向，不断迭代完善产品、服务、技术及团队再造，积极发现和表彰集团内创新、实干的“邦芒追梦人”们。他们勇于担当，敢于突破，在岗位上恪尽职守，发光发热，追求以专业的工作态度和能力造就专业的服务品质。
                                    </div>
                                    <!-- <div class="about_qywh_btn">
                                        <p>了解详情</p>
                                        <img src="@/assets/img/more_off.png" class="img_off">
                                        <img src="@/assets/img/more_on.png" class="img_on">
                                    </div> -->
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>


                </div>
            </div>
        </div>



        <!-- 发展历程 -->
        <div class="fzlc_all" style="position: relative;" id="sm">
            <div class="fzlc_box">
                <div class="fzlc_top">
                    <h3>发展历程</h3>
                    <div class="fzlc_sjz">
                        <div class="fzlc_sjz_item" @click="fzlcClick(1)">
                            <p>现在</p>
                            <div class="yuan" :class="one"></div>
                        </div>
                        <div class="fzlc_sjz_item" @click="fzlcClick(2)">
                            <p>2018 </p>
                            <div class="yuan" :class="two"></div>
                        </div>
                        <div class="fzlc_sjz_item" @click="fzlcClick(3)">
                            <p>2015 </p>
                            <div class="yuan" :class="three"></div>
                        </div>
                        <div class="fzlc_sjz_item" @click="fzlcClick(4)">
                            <p>2007 </p>
                            <div class="yuan" :class="four"></div>
                        </div>
                    </div>
                </div>

                <div class="fzlc_bottom" id="fzlc_bottom">

                    <div class="fzlc_bottom_box" :style="{left:leftNumber+'px'}">
                        <div class="fzlc_bottom_item">
                            <h4>2024</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● “留 • 在浙里”香港引才工作站成立 </p>
                                <p>● 香港人才服务办公室官方指定战略合作伙伴 </p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2023</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 国际事业中心成立 </p>
                                <p>● 邦芒总部大厦启用 </p>
                                <p>● 人力资源五星级企业 </p>
                                <p>● 服务业龙头企业</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2022</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 中国服务业企业500强</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2021</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 全国直营公司突破228家   </p>
                                <p>● 产业发展中心成立</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2020</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 承接全国多个人力资源产业园 </p>
                            </div>
                        </div>
                 
                        <div class="fzlc_bottom_item">
                            <h4>2018</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 北美设立首个海外服务中心</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2017</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 全国布局32个省市自治区</p>
                                <p>● 荣获省级著名商标</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2015</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 香港邦芒成立</p>
                            </div>
                        </div>
                       
                        <div class="fzlc_bottom_item">
                            <h4>2012</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 布局全国百城</p>
                                <p>● 北京、上海、广州、深圳邦芒陆续成立</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2009</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 业务布局浙江全省</p>
                            </div>
                        </div>
                        <div class="fzlc_bottom_item">
                            <h4>2007</h4>
                            <div class="fzlc_bottom_item_text">
                                <p>● 邦芒人力正式成立</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="left_btn" @click="lishiLeftClick()"><img src="@/assets/img/lishi_left.png"></div>
            <div class="right_btn" @click="lishiRightClick()"><img src="@/assets/img/lishi_right.png"></div>
        </div>

        <!-- 荣誉资质 -->
        <div class="honor_box" id="honor">
            <div class="all_box">
                <div class="honor_body">
                    <h3 class="t-center">荣誉图集</h3><div data-v-c9547750="" class="henxian" style="margin-bottom: 30px"></div>
                    <div class="about_honor_box">
                        <honorAbout></honorAbout>
                    </div>
                </div>
            </div>
        </div>

        <!-- 组织架构 -->
        <div class="about_zzjg" id="zzjg">
            <div class="all_box">
                <div class="all_title_two">
                    <h3 class="t-center">组织架构</h3><div data-v-c9547750="" class="henxian" style="margin-bottom: 30px"></div>
                </div>
                <div class="zzjg_body">
                    <h4>邦芒人力</h4>
                    <img src="@/assets/img/zzjg_bg.png">
                    <div class="bmrl_list">
                        <div :class="zzjgNumber == 1?'zzjg_on':'zzjg_off'" @click="zzjgClick(1)" class="bmrl_item">
                            <h5>GOC</h5>
                            <p>运营中心</p>
                        </div>
                        <div :class="zzjgNumber == 2?'zzjg_on':'zzjg_off'" @click="zzjgClick(2)" class="bmrl_item">
                            <h5>TEC</h5>
                            <p>技术中心</p>
                        </div>
                        <div :class="zzjgNumber == 3?'zzjg_on':'zzjg_off'" @click="zzjgClick(3)" class="bmrl_item">
                            <h5>HRC</h5>
                            <p>人力资源中心</p>
                        </div>
                        <div :class="zzjgNumber == 4?'zzjg_on':'zzjg_off'" @click="zzjgClick(4)" class="bmrl_item">
                            <h5>BMC</h5>
                            <p>品宣中心</p>
                        </div>
                        <div :class="zzjgNumber == 5?'zzjg_on':'zzjg_off'" @click="zzjgClick(5)" class="bmrl_item">
                            <h5>FMC</h5>
                            <p>财务中心</p>
                        </div>
                        <div :class="zzjgNumber == 6?'zzjg_on':'zzjg_off'" @click="zzjgClick(6)" class="bmrl_item">
                            <h5>IDC</h5>
                            <p>产业发展中心</p>
                        </div>
                    </div>
                    <div class="bmrl_detail">
                        <div class="sanjiaojiantou" :style="{left:leftStyle+'%'}"></div>
                        <p v-if="zzjgNumber == 1">
                            负责统筹全国各分公司的培训、管理、协调发展。根据公司总体战略目标和经营方针，合理制定运营计划，整理分析运营的实时数据，推动公司及全国市场沿着正确的战略方向发展。</p>
                        <p v-if="zzjgNumber == 2">负责提供公司全流程业务的技术支持，制定以产品开发为中心的战略重点，服务并推动公司的技术进步，增强公司的技术开发和创新能力。</p>
                        <p v-if="zzjgNumber == 3">负责依据公司的发展战略和规划拟订公司人力资源战略和目标、组织改革和架构设计、健全公司招聘和配置使用管理制度系统，促进公司经营战略目标的实现。
                        </p>
                        <p v-if="zzjgNumber == 4">
                            负责公司的整体品牌建设、活动策划、执行宣传及维护。通过新闻宣传管理、媒体关系管理、广告管理、日常舆情监控及危机公关管理，帮助公司树立良好的品牌形象，提升品牌知名度。</p>
                        <p v-if="zzjgNumber == 5">
                            负责根据公司的发展规划和目标，建立符合公司经营需求的财务管理和资本运营体系；健全公司财务管理的各项规章制度；编制、下达并实施公司财务预算；核算公司的生产经营、资金运行情况，配合辅佐公司的整体业务战略。
                        </p>
                        <p v-if="zzjgNumber == 6">负责公司各事业部的整体产业发展规划及策略、产业基础研究；拟订中长期的产业发展规划、投资管理、产业重组、改制等工作，促进产业可持续性发展。
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 合作客户 -->
        <div class="friend_box" id="friend">
            <div class="all_box">
                <div class="friend_body">
                    <h3 class="t-center">合作客户</h3><div data-v-c9547750="" class="henxian" style="margin-bottom: 30px"></div>
                    <friend type="about_son"></friend>
                </div>
            </div>
        </div>

        <foots @navChange="navChange"></foots>
    </div>
</template>
<script>
    import honorAbout from '@/components/honorAbout/index.vue'
    import topBtn from '@/components/topBtn/index.vue'
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    import friend from '@/components/friend/index.vue'
    export default {
        name: 'about',
        data() {
            return {
                one: 'yuan_on',
                two: '',
                three: '',
                four: '',
                leftNumber: 380,
                zzjgNumber: 1,
                leftStyle: 5,
                activeName: 'first',
                footType: '',
                currentYear: new Date().getFullYear() - 2007,
            }
        },
        mounted() {
            this.$refs.topBtn.data = this.$refs.zzz
            if (this.$route.params.type) {
                this.footType = this.$route.params.type
            }
            this.clickNav(this.footType)
            this.scrollInit()

        },
        components: {
            heads,
            foots,
            honorAbout,
            friend,
            topBtn
        },
        methods: {
            navChange(type) {
                if (type == 0) {
                    //关于我们
                    document.getElementById("about").scrollIntoView();
                }
                if (type == 1) {
                    //了解邦芒
                    document.getElementById("about").scrollIntoView();
                }
                if (type == 2) {
                    //发展大事记
                    document.getElementById("sm").scrollIntoView();
                }
                if (type == 3) {
                    //荣誉墙
                    document.getElementById("honor").scrollIntoView();
                }
                if (type == 4) {
                    //企业文化
                    document.getElementById("qywh").scrollIntoView();
                }
            },
            lishiLeftClick() {
                if (this.leftNumber > 0) {
                    this.leftNumber = 0
                    return
                }
                if (this.leftNumber <= 0 && this.leftNumber > -450) {
                    this.leftNumber = -450
                    return
                }
                if (this.leftNumber <= -450 && this.leftNumber > -900) {
                    this.leftNumber = -900
                    return
                }
                if (this.leftNumber <= -900 && this.leftNumber > -1350) {
                    this.leftNumber = -1350
                    return
                }
                if (this.leftNumber <= -1350 && this.leftNumber > -1800) {
                    this.leftNumber = -1800
                    return
                }
                if (this.leftNumber <= -1800 && this.leftNumber > -2250) {
                    this.leftNumber = -2250
                    return
                }
                if (this.leftNumber <= -2250 && this.leftNumber > -2700) {
                    this.leftNumber = -2700
                    return
                }
                if (this.leftNumber <= -2700 && this.leftNumber > -3150) {
                    this.leftNumber = -3150
                    return
                }
                if (this.leftNumber <= -3150 && this.leftNumber > -3600) {
                    this.leftNumber = -3600
                    return
                }
                if (this.leftNumber <= -3600 && this.leftNumber > -4050) {
                    this.leftNumber = -4050
                    return
                }
                if (this.leftNumber <= -4050 && this.leftNumber > -4500) {
                    this.leftNumber = -4500
                    return
                }
                if (this.leftNumber <= -4500 && this.leftNumber > -4950) {
                    this.leftNumber = -4950
                    return
                }

            },
            lishiRightClick() {
                if (this.leftNumber < -4950) {
                    this.leftNumber = -4950
                    return
                }
                if (this.leftNumber >= -4950 && this.leftNumber < -4500) {
                    this.leftNumber = -4500
                    return
                }
                if (this.leftNumber >= -4500 && this.leftNumber < -4050) {
                    this.leftNumber = -4050
                    return
                }
                if (this.leftNumber >= -4050 && this.leftNumber < -3600) {
                    this.leftNumber = -3600
                    return
                }
                if (this.leftNumber >= -3600 && this.leftNumber < -3150) {
                    this.leftNumber = -3150
                    return
                }
                if (this.leftNumber >= -3150 && this.leftNumber < -2700) {
                    this.leftNumber = -2700
                    return
                }
                if (this.leftNumber >= -2700 && this.leftNumber < -2250) {
                    this.leftNumber = -2250
                    return
                }
                if (this.leftNumber >= -2250 && this.leftNumber < -1800) {
                    this.leftNumber = -1800
                    return
                }
                if (this.leftNumber >= -1800 && this.leftNumber < -1350) {
                    this.leftNumber = -1350
                    return
                }
                if (this.leftNumber >= -1350 && this.leftNumber < -900) {
                    this.leftNumber = -900
                    return
                }
                if (this.leftNumber >= -900 && this.leftNumber < -450) {
                    this.leftNumber = -450
                    return
                }
                if (this.leftNumber >= -450 && this.leftNumber < 0) {
                    this.leftNumber = 0
                    return
                }

            },
            fzlcClick(type) {
                this.one = ''
                this.two = ''
                this.three = ''
                this.four = ''
                if (type == 1) {
                    this.leftNumber = 800
                    this.one = 'yuan_on'

                }
                if (type == 2) {
                    this.leftNumber = -1450
                    this.two = 'yuan_on'
                }
                if (type == 3) {
                    this.leftNumber = -2250
                    this.three = 'yuan_on'
                }
                if (type == 4) {
                    this.leftNumber = -4050
                    this.four = 'yuan_on'
                }
            },
            scrollInit() {
                let _this = this
                const nav = document.getElementById("fzlc_bottom")
                var flag; // 鼠标按下
                var downX; // 鼠标点击的x下标
                var scrollLeft; // 当前元素滚动条的偏移量
                let nowIndex; //当前坐标

                nav.addEventListener("mousedown", function (event) {
                    console.log(1)
                    flag = true;
                    downX = event.clientX; // 获取到点击的x下标
                    scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
                    nowIndex = _this.leftNumber
                });
                nav.addEventListener("mousemove", function (event) {
                    console.log(2)
                    if (flag) { // 判断是否是鼠标按下滚动元素区域
                        var moveX = event.clientX; // 获取移动的x轴
                        var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
                        this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
                        if (scrollX < 0) {
                            _this.leftNumber = nowIndex - Math.abs(scrollX);
                        } else {
                            _this.leftNumber = nowIndex + Math.abs(scrollX);

                        }
                        // _this.$forceUpdate();
                    }
                });
                // 鼠标抬起停止拖动
                nav.addEventListener("mouseup", function () {
                    console.log(3)
                    this.leftNumber = nowIndex
                    flag = false;
                });
                // 鼠标离开元素停止拖动
                nav.addEventListener("mouseleave", function (event) {
                    console.log(4)
                    flag = false;
                });

            },
            zzjgClick(number) {
                this.zzjgNumber = number
                if (number == 1) {
                    this.leftStyle = 5
                }
                if (number == 2) {
                    this.leftStyle = 22
                }
                if (number == 3) {
                    this.leftStyle = 39
                }
                if (number == 4) {
                    this.leftStyle = 56
                }
                if (number == 5) {
                    this.leftStyle = 73
                }
                if (number == 6) {
                    this.leftStyle = 90
                }
            },
            clickNav(number) {
                if (number == 1) {
                    document.getElementById("about").scrollIntoView();
                }
                if (number == 2) {
                    document.getElementById("qywh").scrollIntoView();
                }

                if (number == 3) {
                    document.getElementById("honor").scrollIntoView();
                }

                if (number == 4) {
                    document.getElementById("zzjg").scrollIntoView();
                }
                if (number == 5) {
                    document.getElementById("friend").scrollIntoView();
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
.about {
    h3 {
        margin-bottom: 20px;
    }
}
    .btn_box {
        position: absolute;
        top: 0;
    }

    .left_btn {
        position: absolute;
        z-index: 10;
        cursor: pointer;
        left: 30px;
        bottom: 200px;
    }

    .right_btn {
        position: absolute;
        z-index: 10;
        cursor: pointer;
        right: 30px;
        bottom: 200px;
    }

    .friend_box {
        padding-top: 115px;
        padding-bottom: 150px;
        background: url(../../assets/img/about_friend_bg.png) left center no-repeat;
    }

    .about_qywh_btn {
        position: absolute;
        left: 11%;
        bottom: 5px;
        width: 117px;
        height: 41px;

        p {
            position: absolute;
            margin: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            text-align: center;
            line-height: 41px;
        }

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }
    }

    .about_qywh_btn:hover {
        .img_on {
            z-index: 1;
        }

        .img_off {
            z-index: 0;
        }

        p {
            color: #fff;
        }
    }


    .all_box {
        width: 1200px;
        margin: 0 auto;

    }

    .about_nav {
        background: #ededed;
        padding: 30px 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            list-style: none;
            font-size: 18px;
            color: #454545;

        }
    }

    .about_qywh_body {
        margin-top: 80px;
    }

    .about_qywh_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 75px;
    }

    .about_qywh_item_left {
        width: 50%;

        video {
            width: 100%;
        }
    }

    .about_qywh_item_right {
        position: relative;
        width: 50%;
        padding: 32px 0 32px 72px;
        box-sizing: border-box;
        font-size: 18px;
        color: #656565;
    }

    .about_qywh_text {
        text-align: left;
        line-height: 42px;
        font-size: 18px;
        color: #656565;
    }

    .about_qywh_btn {
        text-align: left;
        cursor: pointer;

        p {
            font-size: 18px;
            color: #d4b391;
        }
    }

    .img_off {
        z-index: 1
    }

    .img_on {
        z-index: 0
    }

    .about_qywh {
        padding: 150px 0;
    }

    .banner {
        width: 100%;
        display: block;
    }

    .banner img {
        width: 100%;
        display: block;
    }

    .all_title h3 {
        font-size: 36px;
        margin: 0;
        margin-bottom: 20px;
        color: #333333;
    }

    .henxian {
        width: 45px;
        height: 5px;
        background: #d4b391;
        margin: 0 auto;
    }

    .about_text p {
        font-size: 16px;
        color: #454545;
        line-height: 26px;
        margin: 36px 0;
    }

    .about_text {
        text-align: left;
    }

    .about_sm {
        padding: 90px 0 60px;
        background: url(../../assets/img/anli_bg.png) center center no-repeat;
    }

    .about_sm_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .about_sm_list .about_sm_item:last-child {
        border-right: 0;
    }

    .about_sm_item {
        width: 32%;
        text-align: left;
        border-right: 2px solid #e7e7e7;
        box-sizing: border-box;
        padding: 0 55px;
    }

    .fbt {
        margin-top: 55px;
    }

    .about_sm_list {
        margin-top: 125px;
    }

    .about_sm_item h3 {
        font-size: 24px;
        color: #333333;
        margin: 23px 0;
    }

    .about_sm_item p {
        font-size: 16px;
        color: #333333;
        line-height: 24px;
    }

    .all_title_two {
        h3 {
            font-size: 36px;
            color: #000000;
        }
    }

    .zzjg_body {
        h4 {
            font-size: 36px;
            margin: 0;
            color: #aa1414;
        }
    }

    .bmrl_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .about_zzjg {
        background: #f5f5f5;
        padding: 65px 0 50px;
    }

    .bmrl_detail {
        background: #fff;
        box-sizing: border-box;
        padding: 30px 50px;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        margin-top: 35px;
        position: relative;
    }

    .sanjiaojiantou {
        position: absolute;
        top: -22px;
        width: 0;
        height: 0;
        border-left: 27px solid transparent;
        border-right: 27px solid transparent;
        border-bottom: 22px solid #fff;
    }

    .about_about {
        padding: 115px 0 180px;
    }

    .zzjg_on {
        background: #fff ! important;
    }

    .bmrl_item {
        width: 15%;
        box-sizing: border-box;
        padding: 30px;
        background: #dcdcdc;
        cursor: pointer;

        h5 {
            font-size: 35px;
            color: #aa1414;
            margin: 0;
            margin-bottom: 20px;
        }

        p {
            font-size: 20px;
            color: #444444;
            margin: 0;
        }
    }

    .fzlc_top {
        background: url(../../assets/img/fzlc_bg.png) center center no-repeat;
        background-size: 100% 100%;
        padding-top: 155px;
        padding-bottom: 55px;

        h3 {
            color: #fff;
            font-size: 36px;
        }
    }

    .fzlc_sjz {
        position: relative;
        width: 600px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
    }

    .fzlc_sjz:after {

        content: '';
        width: 97%;
        position: absolute;
        left: 5px;
        bottom: 17px;
        height: 1px;
        background: #b3a19d;
    }

    .yuan {
        width: 31px;
        height: 31px;
        border-radius: 31px;
        background: #b3a19d;
        margin: 0 auto;
        position: relative;
        z-index: 3;
    }

    .yuan_on:after {
        content: '';
        width: 22px;
        height: 22px;
        border-radius: 22px;
        position: absolute;
        background: #ededed;
        left: 4px;
        top: 4px;
    }

    .fzlc_sjz_item {
        cursor: pointer;
    }

    .fzlc_sjz_item p {
        color: #fff;
        font-size: 18px;
        margin: 0;
        margin-bottom: 12px;
    }

    .fzlc_sjz .fzlc_sjz_item:first-child p {
        margin-bottom: 10px;
    }

    .fzlc_bottom {
        height: 440px;
        overflow: hidden;
        position: relative;
    }

    .fzlc_bottom_box {
        padding: 80px 0 120px;
        position: absolute;
        left: 0px;
        top: 0;
        width: 5400px;
        cursor: grab;
    }

    .fzlc_bottom_item {
        display: inline-grid;
        text-align: left;
        float: left;
        margin-right: 90px;
        width: 360px;

        h4 {
            font-size: 24px;
            margin: 0;
            margin-bottom: 8px;
            border-bottom: 1px solid #d3d3d3;
        }
    }

    .fzlc_bottom_item_text {
        font-size: 16px;
        line-height: 26px;

        p {
            margin: 10px 0;
        }
    }

    .honor_box {
        padding-bottom: 150px;
        padding-top: 40px;

        h3 {
            font-size: 36px;
            color: #000000;
        }
    }

    .about_honor_box {
        box-sizing: border-box;
        padding: 75px 50px;
        background: #f5f5f5;
    }

    .friend_body h3 {
        font-size: 36px;
        color: #010101;
    }

    .about_nav_ul li {
        cursor: pointer;
    }
    .t-center {
        text-align: center;
    }
</style>
<style lang="scss">
    .about_qywh_body {
        .el-tabs__item {
            font-size: 28px;
            color: #a7a7a7;
            padding: 0 100px;
        }

        .is-active {
            color: #ab1621 !important;
        }

        .el-tabs__active-bar {
            background-color: #ab1621;
        }

        .el-tabs__item:hover {
            color: #ab1621 !important;
            ;
        }

        .el-tabs__nav {
            padding-bottom: 15px;
        }
    }
</style>