<template>
    <div class="partyDetail">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/party_banner.jpg">
        </div>
        <div ref="zzz"></div>
        <div class="nei_nav">
            <div class="all_box">
                <div class="nei_nav_box">
                    <ul>
                        <li :class="nav_one" @click="gotoParty(0)">时政资讯</li>
                        <li @click="gotoParty(1)">党建活动</li>
                        <li :class="nav_two" @click="gotoParty(2)">党务百科</li>
                        <li @click="gotoParty(3)">联系我们</li> 
                    </ul>
                </div>
            </div>
        </div>
        <div class="all_box">
            <div class="detail">
                <h3>{{dataForm.title}}</h3>
                <div class="time">
                    发布时间：{{dataForm.createDate}}
                </div>
                <div class="detail_text" v-html="dataForm.content">
                </div>
                <div class="laiyuan">来源：{{dataForm.source}}</div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>
<script>
    import partyBanner from '@/assets/img/party_banner.png'
    import newBanner from '@/assets/img/new_banner.png'
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        data() {
            return {
                id: '',
                dataForm: {},
                nav_one:'',
                nav_two:'',
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.id = this.$route.query.id
            }
            if(this.$route.query.type == 1){
                this.nav_one = 'nav_gl'
            }
            if(this.$route.query.type == 2){
                this.nav_two = 'nav_gl'
            }
            this.getDetail()
        },
        components: {
            heads,
            foots
        },
        methods: {
            getDetail() {
                this.$http.get(`/attract_investment/front/show/getNewsDetail/${this.id}`).then(({
                    data: res
                }) => {
                    this.dataForm = res
                })
            },
            gotoParty(number) {
                if (number == 0) {
                    localStorage.setItem('task', 'first')
                }
                if (number == 1) {
                    localStorage.setItem('task', 'second')
                }
                if (number == 2) {
                    localStorage.setItem('task', 'third')
                }
                if (number == 3) {
                    localStorage.setItem('task', 'fourth')
                }
                localStorage.setItem('index', number)
                this.$router.push({
                    name: 'party'
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .banner {
        width: 100%;

        img {
            width: 100%;
            display: block;
        }
    }

    .detail {
        padding: 100px 0;

        h3 {
            font-size: 28px;
            color: #454545;
            margin-bottom: 40px;
        }
    }

    .detail_text {
        text-align: left;
    }

    .laiyuan {
        text-align: left;
        font-size: 16px;
        color: #a7a7a7;
        margin-top: 100px;
    }

    .time {
        text-align: left;
        font-size: 14px;
        color: #a7a7a7;
        margin-bottom: 40px;
    }

    .nei_nav {
        background: #ededed;
    }

    .nav_gl:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: #c81422;
        left: 0;
        bottom: -21px;

    }

    .nei_nav_box {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            margin: 0;
            padding: 22px 0;

            li {
                color: #454545;
                font-size: 14px;
                position: relative;
                cursor: pointer;
            }
        }
    }
</style>