<template>
    <div class="business">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/ywfw_banner.jpg">
        </div>
        <div id="zzz"></div>
        <div class="nav" id="fwwbNav">
            <div class="all_box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="人事服务外包HRO" name="first">
                        <div class="rswbHro_box">
                            <div class="one_title">
                                <h3>人事服务外包HRO</h3>
                                <h4>Human Resource Outsourcing</h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒人事服务外包HRO，根据企业的实际需求，帮助企业简化并整合人力资源管理流程，降低企业人力资源管理成本、优化企业用工风险、实现管理效率最大化。</p>
                                <p>邦芒凭借庞大的全国直营服务网络、先进的信息技术、专业的服务、灵活的产品方案以及对国家人事政策和人才市场的深刻理解，为各个行业不同规模企业客户提供以下内容为主的人事外包服务。
                                </p>
                                <img src="@/assets/img/hro_pic.png">
                            </div>
                        </div>

                        <div class="serve_body">
                            <div class="serve_title">服务内容</div>
                            <div class="serve_list">
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve01.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>人事代理服务</h4>
                                        <p>社会保险服务、住房公积金服务、薪酬服务、档案管理服务、人事手续服务、咨询管理服务、外国人居留及就业证办理服务</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve02.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>商业险服务</h4>
                                        <p>雇主责任险、员工福利险、人身意外险、补充医疗险、高端医疗险</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve03.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>劳务派遣服务</h4>
                                        <p>入职/离职手续办理、员工档案管理、社会保险服务、工伤、劳资纠纷处理、招聘服务、体检服务、培训与员工发展、薪酬管理服务</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve04.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>企业福利平台</h4>
                                        <p>主打全球化集采优势，为企业提供全线福利服务。主营产品有：橙意福豆商城、年节关怀福利、健康管理、卡券定制等。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve05.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>薪酬服务</h4>
                                        <p>薪资核算服务、税务代理、薪资电子化支付、薪资和工资单交付、标准化及企业化报表服务</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve06.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>健康管理服务</h4>
                                        <p>入职体检、职业病体检、企业年度体检、个性化定制体检</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="serve_ys">
                            <div class="ys_title">
                                服务优势
                            </div>
                            <div class="ys_list">
                                <div class="ys_item">
                                    <img src="@/assets/img/ys01.jpg">
                                    <div class="ys_item_title">
                                        <!-- <h3>01.点对点的全国服务</h3> -->
                                        <h4>01.点对点的全国服务</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        公司旗下在全国拥有228家直营公司，形成覆盖400+城市的全国性人力资源外包服务网络，为客户提供点对点的落地服务。
                                    </div>

                                </div>
                                <div class="ys_item">
                                    <img src="@/assets/img/ys02.jpg">
                                    <div class="ys_item_title">
                                        <!-- <h3>02/</h3> -->
                                        <h4>02.丰富完善的行业经验</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        邦芒践行线下人力资源服务{{ currentYear }}年，完成上万项成功案例，积累了丰富的行业经验，为企业提供定制化的综合解决方案。
                                    </div>

                                </div>
                                <div class="ys_item">
                                    <img src="@/assets/img/ys03.jpg">
                                    <div class="ys_item_title">
                                        <!-- <h3>03/</h3> -->
                                        <h4>03.专业稳定的服务团队</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        邦芒拥有一批高素质、稳定的、专业的优秀服务团队，提供多团队支持，专人办理业务，流程规范严谨，服务客户累计3万+。
                                    </div>

                                </div>
                            </div>
                        </div>



                    </el-tab-pane>
                    <el-tab-pane label="业务流程外包BPO " name="second">
                        <div class="bpo_box">
                            <div class="one_title">
                                <h3>业务流程外包BPO</h3>
                                <h4>Business Process Outsourcing </h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒业务流程外包BPO, 通过专业化的人员管理模式和规范系统化的操作流程，承接企业分离出的重复性和非核心岗位，帮助企业简化用工程序，降低人员管理成本，提升核心竞争力。
                                </p>
                                <img src="@/assets/img/bpo_pic.png">
                            </div>
                        </div>
                        <div class="bpo_serve_box">
                            <div class="hro_serve_title">服务内容</div>
                            <div class="bpo_serve">
                                <el-tabs v-model="serve_list" @tab-click="handleClick" class="fwnr_tabs">
                                    <el-tab-pane label="产线外包" name="first">
                                        <span slot="label">
                                            <img src="@/assets/img/bpo_on01.png" class="bpo_on">
                                            <img src="@/assets/img/bpo_off01.png" class="bpo_off">
                                            <p class="fwnr_nav_title">产线外包</p>
                                        </span>
                                        <div class="bpo_item_box">
                                            <div class="bpo_item_text">
                                                整体生产线或部分同类岗位的劳务外包，由专业的外包服务供应商为企业提供从依据生产线特点自行招募员工、配置员工并进行绩效管理、员工关系管理及后勤服务、员工培训与开发、产线日常管理等工作。
                                            </div>
                                            <div class="bpo_item_bottom">
                                                <div class="bpo_item_ul">
                                                    <ul>
                                                        <li>集中精力专注于核心技术、业务和附加值高的业务</li>
                                                        <li>降低季节性、突发性生产招用人员的风险</li>
                                                        <li>减少劳动争议带来的麻烦</li>
                                                        <li>避免机构臃肿，提高企业综合效能</li>
                                                        <li>减少企业在财力、人力上的投入，增加资本运作的回报率</li>
                                                        <li>缩减管理幅度，提高企业管理效能</li>
                                                    </ul>
                                                </div>
                                                <div class="bpo_item_pic">
                                                    <img src="@/assets/img/bpo01.png">
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="仓储物流外包" name="second">
                                        <span slot="label">
                                            <img src="@/assets/img/bpo_on02.png" class="bpo_on">
                                            <img src="@/assets/img/bpo_off02.png" class="bpo_off">
                                            <p class="fwnr_nav_title">仓储物流外包</p>
                                        </span>
                                        <div class="bpo_item_box">
                                            <div class="bpo_item_text">
                                                企业将货物和产品的存储、整理、配送等业务交由专业的外包服务供应商完成，外包服务商通过优化物流供应链管理，降低企业物流用工成本，提高综合服务质量。
                                            </div>
                                            <div class="bpo_item_bottom">
                                                <div class="bpo_item_ul">
                                                    <ul>
                                                        <li>实现资源的优化配置，将有限的资源集中用于发展主业</li>
                                                        <li>通过提高各环节能力的利用率，实现费用节省，增加盈利</li>
                                                        <li>加速商品周转，减少库存，降低经营风险</li>
                                                        <li>优化服务体系，提升企业形象</li>
                                                    </ul>
                                                </div>
                                                <div class="bpo_item_pic">
                                                    <img src="@/assets/img/bpo02.png">
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="客服外包" name="fourth">
                                        <span slot="label">
                                            <img src="@/assets/img/bpo_on04.png" class="bpo_on">
                                            <img src="@/assets/img/bpo_off04.png" class="bpo_off">
                                            <p class="fwnr_nav_title">客服外包</p>
                                        </span>
                                        <div class="bpo_item_box">
                                            <div class="bpo_item_text">
                                                企业将直接与用户联系、为用户提供服务的相关业务部分或全部交由外包服务企业管理。客服外包为互联网、新零售、电商、物流快递、技术、通信等行业提供客户服务全流程的外包服务，帮助企业规范客服管理方式，降低成本，提升客户满意度，实现客服服务精细化、专业化。
                                            </div>
                                            <div class="bpo_item_bottom">
                                                <div class="bpo_item_ul">
                                                    <ul>
                                                        <li>优化客服管理模式，减少培训成本</li>
                                                        <li>降低客服人员用工风险</li>
                                                        <li>客服服务具有稳定性</li>
                                                        <li>提供短时间内的客服支持</li>
                                                        <li>提升业务转化率</li>
                                                    </ul>
                                                </div>
                                                <div class="bpo_item_pic">
                                                    <img src="@/assets/img/bpo04.png">
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="项目整体外包" name="third">
                                        <span slot="label">
                                            <img src="@/assets/img/bpo_on03.png" class="bpo_on">
                                            <img src="@/assets/img/bpo_off03.png" class="bpo_off">
                                            <p class="fwnr_nav_title">项目整体外包</p>
                                        </span>
                                        <div class="bpo_item_box">
                                            <div class="bpo_item_text">
                                                将整个项目委托外包服务供应商进行管理，外包服务供应商从人员招募、培训、人力规划、员工关系、绩效制定及管理，薪资制定及发放到订单管理、生产达成、品质管控等一系列过程的跟踪和实施，协助企业提供整体运营效率，全方位为企业降低风险。
                                            </div>
                                            <div class="bpo_item_bottom">
                                                <div class="bpo_item_ul">
                                                    <ul>
                                                        <li>降低用工及管理风险</li>
                                                        <li>控制人力成本</li>
                                                        <li>推动主营业务增长</li>
                                                        <li>强化核心竞争力</li>
                                                        <li>提升组织效率</li>
                                                    </ul>
                                                </div>
                                                <div class="bpo_item_pic">
                                                    <img src="@/assets/img/bpo03.png">
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                </el-tabs>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="招聘服务外包RPO" name="third">
                        <div class="bpo_box">
                            <div class="one_title">
                                <h3>招聘服务外包RPO</h3>
                                <h4>Recruitment Process Outsourcing</h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒招聘服务外包RPO,
                                    利用自身丰富的行业人才资源、专业的人才评价工具和标准的管理流程优势，帮助企业完成部分或全部员工招聘工作，提升企业招聘质量、优化招聘周期、改善整体行政流程。</p>
                                <img src="@/assets/img/rpo_pic.png">
                            </div>
                        </div>
                        <div class="serve_body">
                            <div class="serve_title">服务内容</div>
                            <div class="serve_list">
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve01.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>批量招聘服务</h4>
                                        <p>批量招聘服务是一种面向基层岗位人才，在最短时间内找到最多、最合适人才的招聘方式，以满足企业招聘大量具备相似技能候选人的需求。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/serve01.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>猎聘服务</h4>
                                        <p>针对专业从事中高端管理人才和技术人才的猎头业务，覆盖金融、房产、化工医药、制造业、消费品等行业。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rpo_fwys">
                            <div class="serve_title">服务优势</div>
                            <div class="rpo_fwys_ul">
                                <ul>
                                    <li>招聘大批量人员，帮助企业节约招聘的时间和成本</li>
                                    <li>为企业搜寻到符合岗位要求的候选人，提高应聘者整体质量 </li>
                                    <li>制定有竞争力的招聘战略与计划</li>
                                    <li>提高企业雇主品牌管理质量</li>
                                    <li>减轻HR负担，使HR管理者能够关注核心管理职能</li>
                                    <li>为企业提供行业人才流动和发展趋势的咨询</li>
                                </ul>
                            </div>
                        </div>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="技术服务外包ITO" name="fourth">
                        <div class="rswbHro_box">
                            <div class="one_title">
                                <h3>技术服务外包ITO</h3>
                                <h4>Information Technology Outsourcing </h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒技术服务外包ITO, 推出人事宝科技技术服务，专注于实现科技与人力完美结合，为各行业提供人力资源技术支持服务。</p>
                                <p>根植于先进的技术条件，人事宝科技始终秉承持续创新与应用实现的服务理念，结合人工智能、云计算、移动互联网等技术，积极为人力资源服务机构搭建数字化创新服务平台，助力人力资源服务在线交付的实现。
                                </p>
                                <img src="@/assets/img/ito_pic.png">
                            </div>
                        </div>

                        <div class="serve_body">
                            <div class="serve_title">核心产品</div>
                            <div class="serve_list">
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/ito01.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>网签系统</h4>
                                        <p>解决了企业签署合同过程中邮寄、低效、不安全等问题，在线电子合同签署，无纸化签署，高效便捷，安全可靠。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/ito02.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>OA办公云</h4>
                                        <p>针对员工生命周期、业务工作流形成闭环管理和灵活配置。极大提升企业人事管理效率。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/ito03.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>薪税云</h4>
                                        <p>薪税系统，帮助企业实现智能算薪、发薪、算税；财务省心、企业放心、降本增效。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/ito04.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>考勤排班云</h4>
                                        <p>智能考勤排班系统，以精细化管理劳动效率为核心，解决企业在外员工的管理难度，考勤数据统计难，考勤规则配置难等问题。</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="serve_ys">
                            <div class="ys_title">
                                服务优势
                            </div>
                            <div class="ys_list">
                                <div class="ys_item">
                                    <img src="@/assets/img/ito_ys01.png">
                                    <div class="ys_item_title">
                                        <h3>01/</h3>
                                        <h4>强大的技术团队</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        拥有近百位具有数十年人力资源管理、网络营销系统开发经验的行业知名高级工程师团队。
                                    </div>

                                </div>
                                <div class="ys_item">
                                    <img src="@/assets/img/ito_ys02.png">
                                    <div class="ys_item_title">
                                        <h3>02/</h3>
                                        <h4>严谨的工作流程</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        在不断的创新和实践中总结出可持续和可信赖的设计流程，坚持与用户一起思考，发现问题、解决问题、并实现客户产品和企业价值的提升。
                                    </div>

                                </div>
                                <div class="ys_item">
                                    <img src="@/assets/img/ito_ys03.png">
                                    <div class="ys_item_title">
                                        <h3>03/</h3>
                                        <h4>优秀的项目累计</h4>
                                    </div>
                                    <div class="ys_item_text">
                                        服务全国3万多家企事业单位,以管理80多万员工为基础,为大数据服务提供项目经验保障。
                                    </div>

                                </div>
                            </div>
                        </div>
                    </el-tab-pane> -->
                    <el-tab-pane label="劳务派遣" name="fifth">
                        <div class="rswbHro_box">
                            <div class="one_title">
                                <h3>劳务派遣</h3>
                                <h4>labor Dispatch </h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒作为具备劳务派遣经营资质的机构，与劳动者订立劳动合同
                                    ，把劳动者派往用工企业，再由用工企业向邦芒支付服务费用的用工形式。有效帮助企业减少劳动争议，降低用人风险，自主灵活用工，专注核心业务发展，提升核心竞争力，真正实现
                                    "省心"、"省力"、"省钱"。
                                </p>
                                <img src="@/assets/img/lwpq_bn.png">
                            </div>
                        </div>

                        <div class="serve_body">
                            <div class="serve_title">服务内容</div>
                            <div class="serve_list">
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq01.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>招聘管理</h4>
                                        <p>提供招聘、面试及入职手续办理服务；负责员工入职全流程引导及服务。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq02.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>劳动合同管理</h4>
                                        <p>提供劳动合同签订、续签、接触，以及试用期转正管理。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq03.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>社会保险</h4>
                                        <p>社保基数审核、申报、缴纳、账户合并与转移及保险理赔。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq04.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>住房公积金</h4>
                                        <p>公积金基数审核、申报、缴纳、账户合并、公积金贷款、支取等咨询。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq05.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>员工管理服务</h4>
                                        <p>用/退工手续办理、代办人才引进、居住证和居转户业务。</p>
                                    </div>
                                </div>
                                <div class="serve_item">
                                    <div class="serve_item_pic">
                                        <img src="@/assets/img/lwpq06.png">
                                    </div>
                                    <div class="serve_item_right">
                                        <h4>政策咨询</h4>
                                        <p>提供人事、劳动有关政策、法规等方面的咨询服务。</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding-bottom: 120px;"></div>
                    </el-tab-pane>
                    <el-tab-pane label="短期用工" name="sixth">
                        <div class="bpo_box">
                            <div class="one_title">
                                <h3>短期用工</h3>
                                <h4>Short Term Employment </h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒针对企业的特定需求，为中短期空缺岗位提供一站式短期用工解决方案，提供人员招聘、管理、绩效考核、退回人员替换等服务，助力企业降本增效，实现更合理有效的人力资源配置。
                                </p>
                                <img src="@/assets/img/dqyg.png">
                            </div>
                        </div>
                        <div class="serve_body">
                            <div class="serve_title">服务内容</div>
                            <div class="dqyg_serve_list">
                                <div class="dqyg_serve_item">
                                    <div class="dqyg_serve_img">
                                        <img src="@/assets/img/dqyg01.png">
                                    </div>
                                    <div class="dqyg_serve_text">
                                        小时工
                                    </div>
                                </div>
                                <div class="dqyg_serve_item">
                                    <div class="dqyg_serve_img">
                                        <img src="@/assets/img/dqyg02.png">
                                    </div>
                                    <div class="dqyg_serve_text">
                                        实习生
                                    </div>
                                </div>
                                <div class="dqyg_serve_item">
                                    <div class="dqyg_serve_img">
                                        <img src="@/assets/img/dqyg03.png">
                                    </div>
                                    <div class="dqyg_serve_text">
                                        临时工
                                    </div>
                                </div>
                                <div class="dqyg_serve_item">
                                    <div class="dqyg_serve_img">
                                        <img src="@/assets/img/dqyg04.png">
                                    </div>
                                    <div class="dqyg_serve_text">
                                        兼职
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rpo_fwys">
                            <div class="serve_title">服务优势</div>
                            <div class="rpo_fwys_ul">
                                <ul>
                                    <li>企业用人方式更加灵活</li>
                                    <li>解决企业短期项目的人员紧缺需求 </li>
                                    <li>有效缓解企业内部编制限额</li>
                                    <li>提升员工整体素质和企业形象</li>
                                    <li>节省企业用工招聘成本</li>
                                    <li>帮助企业实现人员完备管理</li>
                                </ul>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="HR SaaS" name="seventh">
                        <div class="bpo_box">
                            <div class="one_title">
                                <h3>HR SaaS</h3>
                                <h4>Human Resources SaaS Service</h4>
                            </div>
                            <div class="one_text">
                                <p>邦芒科技集成数字化技术平台，为广大用户企业提供一站式HR SaaS系统、HRO SaaS 系统、商业咨询等服务，专注于人力资源行业薪税管理、社保缴纳、客户追踪、人员管理等数字化解决方案的技术交付，能够满足企业降低成本、效率提升、管理优化、服务提升的综合需求。</p>
                                <p>邦芒科技致力于科技与人力的完美结合，为广大用户提供专业的信息技术服务和技术人员支持服务。</p>
                                <img src="@/assets/img/dqyg_bn.png">
                            </div>



                            <div class="serveHx">
                                <div class="ys_title">
                                    核心软件服务
                                </div>
                                <div class="serveHx_body">
                                    <div class="serveHx_body_top">
                                        <div class="serveHx_top_item" :class="hxServeType==1?'gaoliang':''" @click="hxServeType = 1 ">
                                           <img v-if="hxServeType == 1" src="@/assets/img/serveHx01_on.png"/>
                                           <img v-else src="@/assets/img/serveHx01_off.png"/>
                                            <p>e-HRO SaaS系统</p>
                                        </div>
                                        <div class="serveHx_top_item" :class="hxServeType==2?'gaoliang':''" @click="hxServeType = 2">
                                           <img v-if="hxServeType == 2" src="@/assets/img/serveHx02_on.png"/>
                                           <img v-else src="@/assets/img/serveHx02_off.png"/>
                                            <p>背景核实系统</p>
                                        </div>
                                        <div class="serveHx_top_item" :class="hxServeType==3?'gaoliang':''" @click="hxServeType = 3">
                                           <img v-if="hxServeType == 3" src="@/assets/img/serveHx03_on.png"/>
                                           <img v-else src="@/assets/img/serveHx03_off.png"/>
                                            <p>网签系统</p>
                                        </div>
                                    </div>
                                    <div class="serveHx_body_bottom">
                                        <!-- e-HRO SaaS系统 -->
                                        <div class="serveHx_body_bottom_text" v-if="hxServeType == 1">
                                            <p>邦芒科技针对人力资源服务机构业务形态自主研发的行业专属数字化管理系统，功能覆盖人力资源行业各类业务形态。</p>
                                            <p>通过CRM数字化、流程数字化、薪税数字化、社保申报数字化、结算账单数字化、合同签署数字化等数字转型，大幅提升人力资源企业工作效率，降低人员成本、减少沟通损耗。</p>
                                        </div>
                                        <!-- 2、背景核实系统 -->
                                        <div class="serveHx_body_bottom_text" v-if="hxServeType == 2">
                                            <p>邦芒科技推出的背调系统为客户提供全方位的信息核查服务项目，方便客户企业根据公司及岗位情况选择相应项目。</p>
                                            <p>背景核实系统能帮助客户企业识别员工的个人信用、学历学籍验证、基本信息情况、在外任职情况等信息，帮助企业降低雇佣关系管理上面临的风险。</p>
                                        </div>
                                        <!-- 3、网签系统 -->
                                        <div class="serveHx_body_bottom_text" v-if="hxServeType == 3">
                                            <p>邦芒科技推出的网签系统可将线下签署转移到线上，解决了线下用印审批流程长、线下查找合同困难、多文件盖章繁琐、印章纸质成本高、印章外借难以管控、印章不合规使用、效率低下等问题。</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="serve_ys">
                                <div class="ys_title">
                                    服务优势
                                </div>
                                <div class="ys_list">
                                    <div class="ys_item">
                                        <img src="@/assets/img/heSaasYs01.jpg">
                                        <div class="ys_item_title">
                                            <!-- <h3>01.点对点的全国服务</h3> -->
                                            <h4>01.强大的技术团队</h4>
                                        </div>
                                        <div class="ys_item_text">
                                            深耕人力资源行业软件{{ currentYear }}年，技术团队拥有高级开发工程师超300人，具备资深的行业方案解决能力，能够提供专业的技术实施落地方案。
                                        </div>
    
                                    </div>
                                    <div class="ys_item">
                                        <img src="@/assets/img/heSaasYs02.jpg">
                                        <div class="ys_item_title">
                                            <!-- <h3>02/</h3> -->
                                            <h4>02.前沿的数字产品服务</h4>
                                        </div>
                                        <div class="ys_item_text">
                                            为客户提供专业的HRO SaaS产品，覆盖人力资源企业人员管理、算薪发薪管理、招聘管理、外包派遣、社保报税、电子合同等全部业务生态。
                                        </div>
    
                                    </div>
                                    <div class="ys_item">
                                        <img src="@/assets/img/heSaasYs03.jpg">
                                        <div class="ys_item_title">
                                            <!-- <h3>03/</h3> -->
                                            <h4>03.优秀的项目累计</h4>
                                        </div>
                                        <div class="ys_item_text">
                                            超3万家客户服务经验，日常服务员工100w+，拥有丰富的项目服务经验，能提供专业的业务咨询、企业战略规划落地等服务，为大数据服务保驾护航。
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <!-- <img src="@/assets/img/saasImg.png" class="sass_img"> -->
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <foots @navChange="clickNav1"></foots>
    </div>
</template>
<script>
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        name: 'business',
        data() {
            return {
                //1saas 2背调 3网签
                hxServeType:1,
                activeName: 'first',
                serve_list: 'first',
                footType:'',
                currentYear: new Date().getFullYear() - 2007,
            }
        },
        mounted() {
            debugger
            if(this.$route.params.type){
                this.footType = this.$route.params.type
                this.clickNav(this.footType)
                document.getElementById("zzz").scrollIntoView();
               
            }else{
                window.scrollTo(0,0);
            }


        },

        methods: {

            clickNav1(type){
                this.clickNav(type)
                document.getElementById("zzz").scrollIntoView();
            },

            clickNav(type){
                if(type == 0){
                   //行业解决方案 
                   this.activeName = 'first'
                }
                if(type == 1){
                    //人事服务外包HRO
                    this.activeName = 'first'
                }
                if(type == 2){
                    //业务流程外包BPO
                    this.activeName = 'second'
                }
                if(type == 3){
                    //招聘服务外包RPO
                    this.activeName = 'third'
                }
                if(type == 4){
                   //劳务派遣 
                   this.activeName = 'fifth'
                }
                if(type == 5){
                    //短期用工
                    this.activeName = 'sixth'
                }
                if(type == 6){
                    //HR SaaS
                    this.activeName = 'seventh'
                }
      
            }
        },


        components: {
            heads,
            foots
        }
    }
</script>
<style lang="scss" scoped>
.serveHx{
    margin-top: 120px;
    .serveHx_body{
    .serveHx_body_top{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
        border-bottom: 3px solid #bcbcbc;
        .gaoliang:after{
            content:'';
            height: 3px;
            width: 100%;
            position: absolute;
            background: #cc0518;
            left: 0;
        }
        .serveHx_top_item{
            position: relative;
            width: 33%;
            p{
                font-size: 18px;
                color: #000000;
            }
        }
    }
    .serveHx_body_bottom{
        text-align: left;
        .serveHx_body_bottom_text{
            p{
                font-size: 18px;
                color: #080808;
                margin: 40px 0;
            }
        }
    }
}
}

    .dqyg_serve_item {
        width: 23%;
        font-size: 24px;
        color: #333333;
        background: #f2f2f2;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 24px 33px;
    }

    .dqyg_serve_list {
        margin-top: 75px;
    }

    .dqyg_serve_text {
        margin-top: 27px;
        margin-left: 40px;
    }

    .bpo_on {
        display: none;
    }

    .bpo_off {
        display: inline;
    }

    .fwnr_nav_title {
        margin: 0;
        line-height: 0;
    }

    .dqyg_serve_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .banner {
        width: 100%;
    }

    .banner img {
        width: 100%;
        display: block;
    }

    .one_title {
        text-align: left;

        h3 {
            font-size: 36px;
            color: #454545;
            margin: 0;
            margin-bottom: 15px;
        }

        h4 {
            font-size: 18px;
            color: #d4b391;
            margin: 0;
        }
    }

    .bpo_box {
        padding-top: 150px;
    }

    .one_text {
        text-align: left;

        p {
            font-size: 16px;
            color: #454545;
            margin: 38px 0;
            line-height: 26px;
        }
    }

    .serve_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .serve_item {
        width: 49%;
        background: #f2f2f2;
    }

    .serve_title {
        font-size: 36px;
        color: #454545;
        text-align: left;
        font-weight: bold;
    }

    .serve_body {
        padding-top: 105px;
    }

    .serve_list {
        padding-top: 40px;
    }

    .serve_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px 0;
        padding: 15px;
        box-sizing: border-box;
    }

    .serve_ys {
        padding: 110px 0 50px;
    }

    .ys_title {
        font-size: 36px;
        color: #454545;
        text-align: left;
        margin-bottom: 45px;
    }

    .ys_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ys_item {
        width: 32%;
    }

    .serve_item_right {
        width: 80%;
        text-align: left;

        h4 {
            font-size: 24px;
            color: #454545;
            margin: 0;
            margin-bottom: 12px;
        }

        p {
            font-size: 14px;
            color: #666666;
            margin: 0;
            line-height: 18px;
        }
    }

    .ys_item_title {
        font-size: 24px;
        text-align: left;
        padding-top: 35px;
        padding-bottom: 25px;

        h3 {
            color: #000000;
            margin: 0;
        }

        h4 {
            color: #454545;
            margin: 0;
            margin-top: 20px;
        }
    }

    .ys_item_text {
        border-top: 1px solid #d3d3d3;
        font-size: 16px;
        color: #454545;
        padding: 20px 0;
        text-align: left;
        line-height: 24px;
    }

    .rswbHro_box {
        padding-top: 150px;
    }

    .bpo_item_text {
        text-align: left;
        font-size: 18px;
        color: #080808;
        padding: 85px 0 70px;
        line-height: 36px;
    }

    .bpo_item_bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .hro_serve_title {
        text-align: center;
        font-size: 36px;
        color: #454545;
        padding: 105px 0;
    }

    .bpo_item_ul {
        text-align: left;

        ul {
            li {
                margin: 20px 0;
                font-size: 16px;
                color: #454545;
            }
        }
    }

    .rpo_fwys_ul {
        text-align: left;
        margin-top: 40px;

        ul {
            padding-left: 20px;

            li {
                line-height: 36px;
            }
        }
    }

    .rpo_fwys {
        padding: 110px 0 150px;
    }

    .sass_img {
        width: 100%;
        padding-bottom: 100px;
        padding-top: 50px;
    }

    .bpo_serve_box {
        padding-bottom: 150px;

    }
</style>
<style lang="scss">
    .nav {
        .el-tabs__header {
            position: absolute;
            left: 0;
            width: 100%;
            background: #ededed;
            padding: 12px 0 0;
        }

        .el-tabs__item:hover {
            color: #454545;
        }

        .el-tabs__nav {
            height: 50px;
        }

        .el-tabs__nav-wrap {
            width: 1200px;
            margin: 0 auto;
        }

        .el-tabs__item {
            padding: 0 67px;
            color: #454545;
        }

        .el-tabs__active-bar {
            background-color: #c81422;
            height: 4px;
        }

        .el-tabs__item.is-active {
            color: #454545;
        }

        .bpo_serve {
            .el-tabs__header {
                padding: 0;
                position: relative;
                margin: 0 0 15px;
                background: none;
            }

            .el-tabs__nav-wrap {
                overflow: hidden;
                margin-bottom: -1px;
                position: relative;
                width: 1200px;
                margin: 0 auto;
            }

            .el-tabs__nav {
                height: auto;
                padding-bottom: 15px;
            }

            .el-tabs__item {
                padding: 0 140px;
            }

        }
    }

    .fwnr_tabs {

        .el-tabs__item {
            width: 100%;
            padding: 0 !important;
            box-sizing: border-box;

            p {
                color: #666666;
                padding-top: 10px;
            }
        }

        .is-active {
            .bpo_on {
                display: inline;
            }

            .bpo_off {
                display: none;
            }

            p {
                color: #000;
            }
        }
    }
</style>