<template>
    <div class="video_tanchaung">
        <el-dialog :visible.sync="dialogVisible" width="40%" :before-close="handleClose">

            <!-- <video src="@/assets/dj/video.mp4" controls="controls"></video> -->
            
            <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/02ef0d5e243791580028883597/BpcALsQvLl0A.mp4" controls="controls"></video>

        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: false
            }
        },
        methods: {
            init() {
                debugger
                this.dialogVisible = true
            },
        }
    }
</script>
<style lang="scss">
    .video_tanchaung {
        video {
            width: 100%;
        }

        .el-dialog {
            background: none;
            box-shadow: none;
        }

        .el-dialog__headerbtn {
            background: rgba(0, 0, 0, 0.8);
            top: 60px;
            right: -41px;
            width: 60px;
            height: 60px;
            border-top-right-radius: 10px;
        }
    }
</style>