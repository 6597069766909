<template>
    <div class="head">
        <div class="all_box">
            <div class="head_top">
                <div class="head_top_left">
                    Hi，欢迎来到邦芒官网
                </div>
                <div class="head_top_right">
                    <a href="http://en.50bm.com/" style="text-decoration:none">
                        <span class="lgChange" style="text-decoration:none">English</span>
                    </a> | tel:400-891-5050
                </div>
            </div>
            <div class="head_nav">
                <div class="nav_logo">
                    <img src="@/assets/img/logo.png">
                </div>
                <div class="head_nav_list">
                    <ul>
                        <li @click="tabClick('home')" :class="$route.name == 'home'?'gl_on':'gl_off'">首页</li>
                        <li @click="tabClick('about')" :class="$route.name == 'about'?'gl_on':'gl_off'">关于邦芒</li>
                        <li @click="tabClick('business')" :class="$route.name == 'business'?'gl_on':'gl_off'">业务范围</li>
                        <li @click="tabClick('programme')" :class="$route.name == 'programme'?'gl_on':'gl_off'">行业方案
                        </li>
                        <li @click="tabClick('industry')" :class="$route.name == 'industry'?'gl_on':'gl_off'">产业发展中心</li>
                        <li @click="tabClick('responsibility')" :class="$route.name == 'responsibility'?'gl_on':'gl_off'">社会责任
                        </li>
                        <li @click="tabClick('party')" :class="$route.name == 'party'?'gl_on':'gl_off'">党群中心</li>
                        <li @click="tabClick('new')" :class="$route.name == 'new'?'gl_on':'gl_off'">新闻中心</li>
                        <li @click="tabClick('contact')" :class="$route.name == 'contact'?'gl_on':'gl_off'">联系我们</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            tabClick(item) {
                this.$router.push({
                    name: item
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .head_nav_list ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 16px;
        color: #af9575;
        margin-top: 25px;

    }

    .head_nav_list ul li {
        border-left: 1px solid #af9575;
        padding: 0 20px;
        cursor: pointer;
    }

    .head_nav_list ul li:first-child {
        border-left: 0;
        padding: 0 20px 0 20px;
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .head_top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: #af9575;
        border-bottom: 1px solid #e6dfd7;
        padding: 15px 0;
    }

    .head_top_left {
        font-size: 12px;
    }

    .head_top_right {
        font-size: 16px;
    }

    .head_nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 0;
    }

    .head_nav_list ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .gl_on {
        background: radial-gradient(#d4b391,  #b49679);
        border-radius: 5px;
        color: #fff;
    }
    .gl_on+li{
        border-left: 0 !important;
    }
</style>