<template>
    <div class="programme">
        <!-- 置顶按钮 -->


        <!-- <div class="zhiding" ref="zhiding" @click="zhidingClick()">
            <img src="@/assets/img/top_on.png" class="top_on">
            <img src="@/assets/img/top_off.png" class="top_off">
        </div> -->

        <topBtn ref="topBtn"></topBtn>
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/hyfa_banner.jpg">
        </div>
        <div  ref="zzz"></div>
        <!-- 行业案例 -->
        <div class="hyal">
            <div class="all_box">
                <div class="hyal_body">
                    <h3>行业案例</h3>
                    <div class="hyal_swiper">
                        <el-carousel :interval="8000" type="card" indicator-position="none">
                            <el-carousel-item v-for="item in hyalList" :key="item">
                                <div class="swiper_all_box">
                                    <div class="hyal_swiper_pic">
                                        <img :src="item.img">
                                    </div>
                                    <div class="hyal_swiper_text">
                                        <h4>{{item.h3}}</h4>
                                        <div class="hyal_swiper_p">
                                            <p>{{item.p}}</p>
                                        </div>
                                    </div>
                                    <div class="hyal_swiper_more" @click="moreClick(item.type)">
                                        <img src="@/assets/img/fa_more_off.png" class="img_off">
                                        <img src="@/assets/img/fa_more_on.png" class="img_on">
                                    </div>
                                </div>

                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <!-- 案例List -->
        <div class="anliList">
            <div class="anli_item" id="one">
                <div class="anli_one">
                    <div class="all_box list1_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>1</span></p>
                            <h3>能源化工行业顶尖企业</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>知名能源化工企业，世界500强企业，全球员工超10万人。业务包括上游的石油天然气开发和液化天然气，下游油品和化工业务以及项目和技术部门等。</p>
                                <h4>客户痛点</h4>
                                <p>1. 涉及区域多，人员分布广，薪酬核算复杂，人事管理耗时耗力</p>
                                <p>2. 人员管理及数据统计全部采用线下流程，准确性和时效性大大降低</p>
                                <p>3. 员工日常人事管理冗杂，团队流动率高，招聘人员压力大，招聘成本高</p>
                                <h4>解决方案</h4>
                                <P>邦芒凭借自主研发的ERP和HR
                                    SaaS系统，结合钉钉办公软件，推动客户运作的数字化转型，实现了全流程线上化。服务包括：日常考勤流程（入职，打卡，请假，异动，报销，物品领用，离职等）、数据线上实时统计抓取等，有效提升了数据统计的准确性，提高了工作效率；线上排班，一键导入次月排班，员工可以根据系统排班自行上下班打卡，且可以通过手机实时查看自己的班次和工时；线上培训考试，系统生成考试成绩和考试结果分析；系统抓取薪资基础数据，一键算薪，一键生成，一键发薪。邦芒提供的数字化转型有效帮助客户企业建立了人事管理闭环体系。
                                    <p>在解决现有问题的基础上，邦芒根据劳动生产率和单升人工成本数据，从专业的人力资源角度出发，结合实际运营情况给予客户提高劳动生产率和降低人工成本的建议，实现合理安排人员编制、优化人力资源结构、合理排班，达到了劳效提升的效果。
                                    </p>
                                </P>
                                <p>通过业务外包合作，大幅减少客户单位的人事事务性工作，帮助客户单位集中精力提升核心竞争力，同时降低招聘、培训、管理等各项用工成本。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="anli_item" id="two">
                <div class="anli_two list1_box">
                    <div class="all_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>2</span></p>
                            <h3>餐饮行业老牌企业</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>知名餐饮集团，成立于二十世纪九十年代，经过数十年，发展为“500强”股份制企业。经营区域涉及北京、上海、天津、重庆、广东等多个省市。</p>
                                <h4>客户痛点</h4>
                                <p>1. 员工基数大、流动量大，基础人事工作繁杂，入离职手续办理、人事档案管理，社保公积金增减都需要及时跟进</p>
                                <p>2. 项目点分布各地，需要安排临时工，用工风险高</p>
                                <p>3. 临时工的薪资发放工作量巨大，不同项目点结算标准不同，结算周期不同，人事算薪面临频次高，标准不一的问题，财务按期支付压力大</p>
                                <h4>解决方案</h4>
                                <P>邦芒配备专业化的服务团队，提供数据化、系统化、属地化的专业人力资源服务。运用网签系统，简化线下复杂签署环节，对未在规定时间签署合同的员工，系统自动提醒催签，有效减少了用工风险。
                                </P>
                                <p>邦芒运用自主研发的人力资源信息管理系统——人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，一键申报海量员工的社保、公积金、商业险的增员、减员，有效缓解人事工作量大的问题。
                                </p>
                                <p>针对临时工问题，邦芒制定针对性商业险方案，不仅避免了用工风险，而且降低投保普通商业险方案的成本。邦芒自主研发的发薪平台，设定不同项目点发薪公式及发薪节点，能够做到一键发薪、准确发薪、按时发薪。同时，发薪平台打通100+银行端口，支持发薪到支付宝、银行卡，解决了临时工收款账户限制的问题。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="anli_item" id="three">
                <div class="anli_three list1_box">
                    <div class="all_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>3</span></p>
                            <h3>居民服务行业翘楚</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>知名居民服务企业，截至现在，门店规模覆盖全国近百个城市，拥有近700家直营门店。</p>
                                <h4>客户痛点</h4>
                                <p>1. 员工基数大，覆盖城市广，员工的社保公积金增减、社保相关事务处理耗费大量精力</p>
                                <p>2. 各地政策复杂多变，人力耗费成本高</p>
                                <p>3. 公司发展迅速，市场不断扩张，亟需员工社保解决方案</p>
                                <h4>解决方案</h4>
                                <P>48小时内成立专项项目组，对现有人员进行梳理，准确及时完成人员的参保转移。同时建立漏缴断缴防控机制，保证参减保工作百分百无遗漏。</P>
                                <p>邦芒运用自主研发的人力资源信息管理系统——人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，时刻把握政策变动，及时追踪社保增减结果，有效落实各个服务节点，做到全过程有效把控。
                                </p>
                                <p>邦芒旗下共开设228家直营分公司，400+城市社保公积金可落地，100%匹配客户单位所有门店，属地分公司一对一管理社保公积金账户，充分保证生育报销、工伤申报的时效性。不仅快速解决了招聘人员及员工在五险一金上的个性化问题，也及时响应了社保公积金账户开立需求，让人事从繁杂的社保事务中脱离出来。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="anli_item" id="four">
                <div class="anli_four list1_box">
                    <div class="all_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>4</span></p>
                            <h3>物流行业头部企业</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>知名物流企业，中国500强，全球员工超10万人。业务涉及快运、整车、仓储与供应链等。</p>
                                <h4>客户痛点</h4>
                                <p>1. 员工基数大，零散分布在全国百余个城市，入离职及各类社保事务的扣缴、申领等手续纷繁复杂</p>
                                <p>2. 各地劳动纠纷案件频发，异地处理人力物力成本耗费高</p>
                                <p>3. 一线员工人员流动率大，不稳定性强，招聘压力大</p>
                                <h4>解决方案</h4>
                                <P>邦芒为客户配置经验丰富的客户经理作为第一对接人，客户的全国落地业务均可直接与第一对接人沟通，真正实现一地委托，全国服务。</P>
                                <p>利用线上网签系统，员工劳动合同的签订实现无纸化和远程化，合同文件管理方便便捷，数万员工合同均实现了在线签约和存档管理，极大地改善了原有合同签订费时费力且保管过程复杂等问题，降低了客户人力资源部50%以上的基础工作量。
                                </p>
                                <p>邦芒运用自主研发的人力资源信息管理系统——人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，一键申报海量员工社保、公积金的增员、减员情况，一键算薪、个税申报及准确及时发薪。
                                </p>
                                <p>邦芒旗下的企业一站式法律外包及咨询顾问律师服务平台——劳博士，配备20人的律师团队和相应的顾问、行政团队，凭借着在劳动合同的审核和起草；劳动纠纷的处理等方面的丰富实战经验，为客户提供了完善的人事法律咨询和顾问服务。
                                </p>
                                <p>邦芒通过228家分公司运营的多家人力资源市场，结合线上的聘也平台，共同搭建完整高效的招聘矩阵，在全国400+城市同步协作招聘，为客户尤其是在一线的招聘人员提供极大助力。服务期间，对于原有站点的人员补位和新站点的人员新招，做到了90%以上招聘到岗响应。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="anli_item" id="five">
                <div class="anli_five list1_box">
                    <div class="all_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>5</span></p>
                            <h3>亚太地区知名互联网上市企业</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>亚太地区知名的智能语音和人工智能上市企业，入选《2023人工智能企业百强》。</p>
                                <h4>客户痛点</h4>
                                <p>1. 员工基数大，覆盖范围广，管理成本高</p>
                                <p>2. 员工的社保、薪资相关事务处理耗费大量精力</p>
                                <p>3. 异地处理劳动纠纷难度大</p>
                                <h4>解决方案</h4>
                                <P>邦芒凭借自主研发的人力资源SaaS系统，推动客户人事服务数字化转型，实现了全流程线上化。服务包括：员工入离调转、员工档案管理、员工社保缴纳、社保增减员、薪资核算发放。系统化管理人事条线，降低客户人力资源管理成本；系统化管理薪资体系，保证数据准确性，提升员工满意度。</P>
                                <p>同时邦芒配备20人的律师团队，凭借着在劳动纠纷处理方面的丰富实战经验，为客户提供了完善的人事法律咨询和顾问服务，有效帮助客户降低用工风险。
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="anli_item" id="six">
                <div class="anli_five list1_box">
                    <div class="all_box">
                        <div class="anli_item_body">
                            <p class="anli_case">CASE <span>6</span></p>
                            <h3>IT行业知名企业</h3>
                            <div class="anli_text">
                                <h4>服务客户</h4>
                                <p>中宣部负责电影档案管理、电影艺术研究工作的直属正局级事业单位，是国家级电影专业档案馆，国际电影资料馆联合会（FIAF）正式会员，是我国电影国际交流的重要平台。</p>
                                <h4>客户痛点</h4>
                                <p>客户面临的问题涉及历史、文化传承及政治色彩，影像修复技术门槛高，需复杂的图像处理技术，对团队的专业知识和经验要求严苛。同时，项目急需专业人才以保障修复质量和进度，时间因素也是一大挑战，需要在有限时间内完成大量修复工作。</p>
                                <h4>解决方案</h4>
                                <P>1.精准匹配技术人才：通过高效筛选机制，确保每位候选人具备卓越的专业技能和丰富经验，为项目高标准完成奠定基础。</P>
                                <p>2.迅捷人才供应链：利用庞大的人才储备网络，快速响应客户需求，缩短人才到位时间。
                                </p>
                                <p>3.定制化IT人员外包：提供专业IT人员外包服务，外包专家快速适应团队，确保技术任务高效执行。</p>
                                <p>4.灵活应急支援：在接到需求后2小时内响应，48小时内推荐候选人，确保人员快速到位。</p>
                                <p>5.持续优化与进步：不断探索新技术和管理方法，提高团队执行力和项目连续性，确保高质量服务。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>
<script>
    
    import hyal01 from '@/assets/img/hyal_swiper01.png'
    import hyal02 from '@/assets/img/hyal_swiper02.png'
    import hyal03 from '@/assets/img/hyal_swiper03.png'
    import hyal04 from '@/assets/img/hyal_swiper04.png'
    import hyal05 from '@/assets/img/hyal_swiper05.png'


    import topBtn from '@/components/topBtn/index.vue'
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        data() {
            return {
                currentId: 1,
                listName: [], //滚动的列表
                hyalList: [{
                    h3: '居民服务行业翘楚',
                    p: '邦芒运用自主研发的人力资源信息管理系统，帮助客户单位有效落实各个服务节点，做到全过程有效把控。',
                    img: hyal03,
                    type: 3
                }, {
                    h3: '能源化工行业顶尖企业',
                    p: '邦芒凭借自主研发的ERP和HR SaaS系统，帮助客户单位建立人事管理闭环体系，实现完美的数字化转型。通过业务外包合作，大幅减少客户单位的人事事务性工作，降低各项用工成本。',
                    img: hyal01,
                    type: 1
                }, {
                    h3: '餐饮行业老牌企业',
                    p: '邦芒配备专业化的服务团队，为客户单位提供数据化、系统化、属地化的专业人力资源服务。制定针对性的商业险方案，有效减少企业用工风险，降低投保普通商业险方案的成本。',
                    img: hyal04,
                    type: 2
                }, {
                    h3: '物流行业头部企业',
                    p: '邦芒负责客户单位全部岗位人员的人力资源服务。通过业务流程外包，助力客户单位集中精力专注核心业务，提高整体管理效能。',
                    img: hyal02,
                    type: 4
                }, {
                    h3: '亚太地区知名互联网上市企业',
                    p: '邦芒凭借自主研发的人力资源SaaS系统，推动客户人事服务数字化转型，实现了全流程线上化。',
                    img: hyal05,
                    type: 5
                } , {
                    h3: 'IT行业知名企业',
                    p: '邦芒为客户提供定制化的IT人员外包服务，通过庞大的人才储备网络，快速响应客户需求，帮助客户精准匹配专业技术人才。',
                    img: hyal05,
                    type: 6
                }]
            }
        },
        components: {
            heads,
            foots,topBtn
        },

        mounted() {
            this.$refs.topBtn.data = this.$refs.zzz
        },
        // mounted() {
        //     // 向页面添加股东事件
        //     window.addEventListener('scroll', this.handleScroll, true);
        //     // this.handleScroll()
        // },
        // destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
        //     window.removeEventListener('scroll', this.handleScroll)
        // },


        methods: {
            // //点击置顶按钮
            // zhidingClick() {
            //     this.$refs.zzz.scrollIntoView({
            //         behavior: 'smooth', //顺滑的滚动
            //     })
            // },

            // //滚动事件
            // handleScroll() {
            //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            //     if (scrollTop > 0) {
            //         this.$refs.zhiding.classList.value = 'zhiding zhiding_on'
            //     } else {
            //         this.$refs.zhiding.classList.value = 'zhiding zhiding_off'
            //     }
            // },


            moreClick(type) {
                if (type == 1) {
                    document.getElementById("one").scrollIntoView();
                }
                if (type == 2) {
                    document.getElementById("two").scrollIntoView();
                }
                if (type == 3) {
                    document.getElementById("three").scrollIntoView();
                }
                if (type == 4) {
                    document.getElementById("four").scrollIntoView();
                }
                if (type == 5) {
                    document.getElementById("five").scrollIntoView();
                }
                if (type == 6) {
                    document.getElementById("six").scrollIntoView();
                }
                
            },
        },
    }
</script>
<style lang="scss" scoped>
    .hyal_swiper_more {
        width: 140px;
        height: 40px;
        text-align: center;
        margin: 0 auto;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 0
        }

        .img_off {
            z-index: 1;
        }

        .img_on {
            z-index: 0;
        }
    }

    .hyal_swiper_more:hover {
        .img_off {
            z-index: 0;
        }

        .img_on {
            z-index: 1;
        }
    }

    /* .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    } */
    .hyal_swiper_p {
        font-size: 16px;
        color: #999999;
        text-align: left;
        line-height: 28px;
    }

    .hyal_body {
        h3 {
            font-size: 36px;
            color: #454545;
            text-align: left;
            margin: 157px 0px 50px;
        }
    }

    .banner {
        width: 100%;

        img {
            width: 100%;
            display: blank
        }
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .anli_one {
        background: url(../../assets/img/hyfa01.jpg) center center no-repeat;
        background-size: 100% 100%;
    }

    .anli_two {
        background: url(../../assets/img/hyfa02.jpg) center center no-repeat;
        background-size: 100% 100%;
    }

    .anli_three {
        background: url(../../assets/img/hyfa03.jpg) center center no-repeat;
        background-size: 100% 100%;
    }

    .anli_four {
        background: url(../../assets/img/hyfa04.jpg) center center no-repeat;
        background-size: 100% 100%;
    }

    .anli_five {
        background: url(../../assets/img/hyal_swiper05.png) center center no-repeat;
        background-size: 100% 100%;
    }

    .hyal_swiper_text {
        padding: 50px 50px 0 50px;
        height: 315px;
        box-sizing: border-box;

        h4 {
            font-size: 24px;
            color: #454545;
            margin: 0;
            margin-bottom: 35px;
        }
    }

    .anli_item_body {
        position: relative;
        background: #fff;
        width: 95%;
        margin: 0 auto;
        text-align: left;
        padding: 100px 120px;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        h3 {
            font-size: 48px;
            color: #ab1621;
            margin: 0;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;
        }

        .anli_text {
            h4 {
                font-size: 20px;
                color: #c2a180;
                border: 1px dashed #d4b391;
                display: inline-block;
                padding: 8px 14px;
                margin: 0;
                margin-top: 45px;
            }

            p {
                font-size: 14px;
                color: #010101;
                margin: 0;
                line-height: 24px;
                margin: 16px 0;

            }
        }
    }

    .list1_box {
        padding-top: 105px;
    }

    .anli_item {
        margin: 100px 0;
    }

    .anli_case {
        font-size: 150px;
        color: #f4f3f3;
        position: absolute;
        margin: 0;
        z-index: 0;
        left: 0;
        top: -8px;

        span {
            font-weight: 800;
        }
    }

    .swiper_all_box {
        width: 58%;
        margin: 0 auto;
        background: #ededed;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
        padding-bottom: 35px;

        img {
            width: 100%;
        }
    }
</style>
<style lang="scss">
    .hyal_swiper {
        .el-carousel__container {
            height: 700px;
        }

        .el-carousel__item--card {
            background: none;
        }

        .el-carousel__arrow:hover {
            background-color: inherit;
        }

        .el-carousel__arrow--left {
            background: url(../../assets/img/hyal_left.png) center center no-repeat;
            background-size: 100% 100%;
            width: 20px;
            height: 36px;

            i {
                display: none;
            }
        }

        .el-carousel__arrow--right {
            background: url(../../assets/img/hyal_right.png) center center no-repeat;
            background-size: 100% 100%;
            width: 20px;
            height: 36px;

            i {
                display: none;
            }
        }
    }



    /* .zhiding {
        position: fixed;
        cursor: pointer;
        right: 50px;
        bottom: -80px;
        width: 62px;
        height: 62px;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
        }

        .top_on {
            display: none;
        }
    }

    .zhiding_off {
        bottom: -80px;
        transition-property: bottom;
        transition-duration: 1s;
    }

    .zhiding_on {
        bottom: 50px;
        transition-property: bottom;
        transition-duration: 1s;
    }

    .zhiding:hover {
        .top_on {
            display: block;
        }

        .top_off {
            display: none;
        }
    } */
</style>