<template>

    <div class="honor_body">
        <!-- <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
            <ul class="ul-item">
                <li class="li-item" v-for="(item, index) in listData" :key="index">
              {{item}}
                    <img :src="item">
                </li>
            </ul>
        </vue-seamless-scroll> -->



        <div class="ceshi">

            <hooper :infiniteScroll="true" :itemsToShow="5" :autoPlay="true" :playSpeed="3000">
                <slide v-for="(item,index) in listData" :key="index">
                    <div class="honor_item_box">
                        <img :src="item">
                    </div>
                </slide>

                <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>

        </div>
    </div>

</template>
<script>
    import {
        Hooper,
        Slide,
        Navigation as HooperNavigation
    } from 'hooper';
    import 'hooper/dist/hooper.css';
    // important honor
    export default {
        data() {
            return {

                // classOption: {
                //     limitMoveNum: 2,
                //     direction: 3,
                // },
                listData: [
  '/荣誉奖牌/1.png',  '/荣誉奖牌/2.png',
  '/荣誉奖牌/3.png',  
  '/荣誉奖牌/5.png',  '/荣誉奖牌/6.png',
  '/荣誉奖牌/7.png',  '/荣誉奖牌/8.png',
  '/荣誉奖牌/9.jpg',  '/荣誉奖牌/10.jpg',
  '/荣誉奖牌/11.png', '/荣誉奖牌/12.png',
  '/荣誉奖牌/13.png', '/荣誉奖牌/14.png',
  '/荣誉奖牌/15.png', '/荣誉奖牌/16.png',
  '/荣誉奖牌/17.png', '/荣誉奖牌/18.png',
  '/荣誉奖牌/19.png', '/荣誉奖牌/20.png',
  '/荣誉奖牌/21.png', '/荣誉奖牌/22.png',
  '/荣誉奖牌/23.png', '/荣誉奖牌/24.png',
  '/荣誉奖牌/25.jpg', '/荣誉奖牌/26.jpg',
  '/荣誉奖牌/27.jpg', '/荣誉奖牌/28.jpg',
  '/荣誉奖牌/29.jpg', '/荣誉奖牌/30.jpg',
  '/荣誉奖牌/31.jpg', '/荣誉奖牌/32.jpg',
  '/荣誉奖牌/33.jpg', '/荣誉奖牌/34.jpg',
  '/荣誉奖牌/35.jpg', '/荣誉奖牌/36.jpg'
]
            }
        },
        mounted() {
            document.getElementsByClassName(`hooper-prev`)[0].innerHTML = ''
            document.getElementsByClassName(`hooper-next`)[0].innerHTML = ''
        },
        components: {
            Hooper,
            Slide,
            HooperNavigation
        }
    }
</script>
<style lang="scss" scoped>
    .honor_item_box {
        width: 190px;
        height: 138px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 5px;
        background: url(../../assets/img/honorbg.png) center center no-repeat;
        background-size: 100%;

        img {
            width: 100%;
        }
    }

    .ceshi {
        .hooper {
            height: 150px;
        }

    }
</style>
<style lang="scss">
    .ceshi {
        .hooper-prev {
            background: url(../../assets/img/honor_left.png) center center no-repeat;
            left:-40px;
            width:30px;
            height:42px;
            background-size: 100% 100%;
        }
        
        .hooper-next {
            background: url(../../assets/img/honor_right.png) center center no-repeat;
            right:-40px;
            width:30px;
            height:42px;
            background-size: 100% 100%;
        }
    }
</style>