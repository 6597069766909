<template>
    <div class="party">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/party_banner.png">
        </div>












        <div class="nav dwbk_nav" id="dj_nav">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="时政资讯" name="first">
                    <div class="szzx">
                        <div class="all_box">
                            <div class="szzx_title other">
                                <h3>时政资讯</h3>
                            </div>
                            <div class="szzx_list">
                                <div class="szzx_item" v-for="item in szzxList" @click="goToDetail(item.id,1)">
                                    <div class="szzx_item_left">
                                        <h4>{{item.title}}</h4>
                                        <div class="szzx_item_nat">
                                            <p>来源：<span style="margin-right:30px;">{{item.source}}</span>
                                                时间:<span>{{item.releaseTime}}</span></p>
                                        </div>
                                        <div class="szzx_item_txt">
                                            {{item.summary}}【...】
                                        </div>
                                    </div>
                                    <div class="szzx_item_right">
                                        <img :src="item.imageUrl">
                                    </div>
                                </div>
                            </div>

                            <div class="fenye_box">
                                <el-pagination :current-page="page" :page-sizes="[5,10, 20, 50, 100]" background
                                    :page-size="limit" :total="total" layout="total, sizes, prev, pager, next, jumper"
                                    @size-change="pageSizeChangeHandle" @current-change="pageCurrentChangeHandle">
                                </el-pagination>
                            </div>
                        </div>
                    </div>


                </el-tab-pane>
                <el-tab-pane label="党建活动" name="second">

                </el-tab-pane>
                <el-tab-pane label="党务百科" name="third">
                    <div class="szzx">
                        <div class="all_box">
                            <div class="szzx_title">
                                <el-row>
                                    <el-col :span="8">
                                        <h3>党务百科</h3>
                                    </el-col>
                                    <el-col :span="16" class="submit_bo_fdj">
                                        <el-input v-model="dwbkTitle" placeholder="请输入关键字"></el-input>
                                        <div class="fdj_box" @click="getDwbk()">
                                            <img src="@/assets/img/fangdajing.png">
                                        </div>
                                    </el-col>

                                </el-row>
                            </div>
                            <div class="dwbk_list">
                                <div class="dwbk_item" v-for="item in dwbkList" @click="goToDetail(item.id,2)">
                                    <h3>{{item.title}}</h3>
                                    <div class="dwbk_item_pic">
                                        <img src="@/assets/img/flag.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane label="联系我们" name="fourth">
                    <div class="szzx">
                        <div class="all_box">
                            <div class="szzx_title other">
                                <h3>联系我们</h3>
                            </div>
                            <div class="lxwm_box">
                                <div class="lxwm_top">
                                    <div class="lxwm_top_left">
                                        <h3>邦芒服务外包有限公司党支部</h3>
                                    </div>
                                    <div class="lxwm_top_right">
                                        <p>书记：施玉亭</p>
                                        <p>对接人：施玉亭</p>
                                        <p>电话：13511340620</p>
                                        <p>地址：平湖市总商会大厦B座8楼</p>
                                        <p>邮箱：shiyt@50bm.com</p>
                                    </div>
                                </div>
                            </div>










                            <div class="lxwm_list">
                                <div class="lxwm_item">
                                    <h3>上海邦芒人力资源有限公司党支部</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：余再东</p>
                                        <p>对接人：孙轶雯</p>
                                        <p>电话：18357373393</p>
                                        <p>地址：上海市长宁区天山路600弄1号同达创业大厦2203室</p>
                                        <p>邮箱：syw@50bm.com</p>
                                    </div>
                                </div>
                                <div class="lxwm_item">
                                    <h3>宁波邦芒人力资源有限公司党支部</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：练惠映</p>
                                        <p>对接人：练惠映</p>
                                        <p>电话：13567875898</p>
                                        <p>地址：宁波市鄞州区樟树街515号汉德城大厦C座5楼</p>
                                        <p>邮箱：nb@50bm.com</p>
                                    </div>
                                </div>
                                <div class="lxwm_item">
                                    <h3>嘉兴前程人力有限公司党支部</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：张一凡</p>
                                        <p>对接人：解婧</p>
                                        <p>电话：18006838891</p>
                                        <p>地址：嘉兴市秀洲区未来科技广场9号楼2楼</p>
                                        <p>邮箱：zhangyf@50bm.com</p>
                                    </div>
                                </div>
                                <div class="lxwm_item">
                                    <h3>江苏邦芒服务外包有限公司湖北分公司支部委员会</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：施申良</p>
                                        <p>对接人：施申良</p>
                                        <p>电话：15623241872</p>
                                        <p>地址：武汉市东湖新技术开发区现代光谷世贸中心D栋11楼</p>
                                        <p>邮箱：shisl@50bm.com</p>
                                    </div>
                                </div>
                                <div class="lxwm_item">
                                    <h3>厦门邦芒服务外包有限公司</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：陈晓燕</p>
                                        <p>对接人：陈晓燕</p>
                                        <p>电话：13129520601</p>
                                        <p>地址：厦门市湖里区高林中路469号新景地大厦15楼07-12室</p>
                                        <p>邮箱：chenxiaoy@50bm.com</p>
                                    </div>
                                </div>
                                <div class="lxwm_item">
                                    <h3>深圳邦芒人力资源有限公司党支部</h3>
                                    <div class="lxwm_item_text">
                                        <p>书记：徐文新</p>
                                        <p>联系人：钟州</p>
                                        <p>电话：15919956318</p>
                                        <p>地址：深圳市福田区竹子林紫竹七道26号教育科技大厦23楼</p>
                                        <p>邮箱：zhongz@50bm.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="szzx" :class="activeName=='second' ? 'djhd_on':'djhd_off'">
            <div class="all_box">
                <div class="szzx_title other">
                    <h3>党建活动</h3>
                </div>
                <div class="djhd_swiper">
                    <div class="swiper-container gallery-top1">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item,index) of swiperList">
                                <span>{{item.h3}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-next swiper-button-white">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="swiper-button-prev swiper-button-white">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="swiper-container gallery-thumbs1">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper-bottom" v-for="(item,index) of swiperList">
                                <div class="swiper_box">
                                    <!-- 单张图片 -->
                                    <div class="swiper_pic" v-if="item.id == 1 || item.id == 3">
                                        <img :src="item.data">
                                    </div>
                                    <!-- 视频 -->
                                    <div class="siper_video" v-if="item.id == 2" @click="clickVideo">
                                        <div class="videoStop">
                                            <img src="@/assets/img/stop.png">
                                        </div>
                                        <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/02ef0d5e243791580028883597/BpcALsQvLl0A.mp4" muted autoplay="autoplay" muted="true" loop></video>
                                        <!-- <video :src="item.data" muted autoplay="autoplay" muted="true" loop></video> -->
                                    </div>
                                    <!-- 轮播图片 -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <videoWindow ref="videoWindow" v-if="videoWindowVisible"></videoWindow>
        <foots @navChange="navChange"></foots>
    </div>
</template>
<script>
    import Swiper from "swiper"
    // 庆祝建党100周年南湖游活动
    import dj01 from '@/assets/dj/dj01.jpg'
    import dj02 from '@/assets/dj/dj02.jpg'
    // import video from '@/assets/dj/video.mp4'


    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    import videoWindow from './video-window'
    export default {
        name: 'party',
        data() {
            return {
                videoWindowVisible: false,
                activeName: 'first',
                page: 1,
                limit: 10,
                total: '',
                szzxList: [],
                dwbkList: [],
                djhdList: [],
                djhdIndex: '',
                dwbkTitle: '',
                swiperList: [{
                        h3: '邦芒人力全体员工献礼祖国70华诞',
                        // data: video,
                        id: 2
                    },
                    {
                        h3: '庆祝建党100周年南湖游活动',
                        data: dj01,
                        id: 1
                    },

                    {
                        h3: '井冈山红色之旅 ',
                        data: dj02,
                        id: 3
                    },
                ]
            }
        },
        mounted() {
            this.tableLocal()
            this.galleryThumbsLunbo();
            this.galleryTopLunbo();
            this.bdsjjb()
            if (this.$route.params.type != null) {
                this.navChange(this.$route.params.type)
            }else{
                window.scrollTo(0, 0);
            }
            // document.getElementById("dj_nav").scrollIntoView();
        },
        components: {
            heads,
            foots,
            videoWindow
        },
        methods: {

            bdsjjb() {
                let _this = this
                let a = document.getElementsByClassName('gallery-top1')
                let b = a[0].getElementsByClassName('swiper-slide')
                for (let i = 0; i < b.length; i++) {
                    b[i].onclick = function () {
                        _this.clickSwiperSide(i, b)
                    }
                }
            },

            clickSwiperSide(index, doc) {
                // 邦芒集团全体员工献礼祖国70华诞 0 3 6 9
                // 庆祝建党100周年南湖游活动 1 4 7
                // 井冈山红色之旅 2 5 8
                let swiperTitle = document.getElementsByClassName('swiper-wrapper')[0]
                let swiperBody = document.getElementsByClassName('swiper-wrapper')[1]
                swiperTitle.style.transitionDuration = '300ms'
                swiperBody.style.transitionDuration = '300ms'
                if (index == 0 || index == 3 || index == 6 || index == 9) {
                    for (let j = 0; j < doc.length; j++) {
                        if (j == 3) {
                            doc[3].className = 'swiper-slide swiper-slide-active'
                        } else {
                            doc[j].className = 'swiper-slide'
                        }
                    }
                    swiperTitle.style.transform = 'translate3d(-800px, 0px, 0px)'
                    swiperBody.style.transform = 'translate3d(-2400px, 0px, 0px)'
                }
                if (index == 5 || index == 2 || index == 8) {
                    for (let j = 0; j < doc.length; j++) {
                        if (j == 2) {
                            doc[2].className = 'swiper-slide swiper-slide-active'
                        } else {
                            doc[j].className = 'swiper-slide'
                        }
                    }
                    swiperTitle.style.transform = 'translate3d(-400px, 0px, 0px)'
                    swiperBody.style.transform = 'translate3d(-1200px, 0px, 0px)'
                }

                if (index == 1 || index == 4 || index == 7) {
                    for (let j = 0; j < doc.length; j++) {
                        if (j == 4) {
                            doc[4].className = 'swiper-slide swiper-slide-active'
                        } else {
                            doc[j].className = 'swiper-slide'
                        }
                    }
                    swiperTitle.style.transform = 'translate3d(-1200px, 0px, 0px)'
                    swiperBody.style.transform = 'translate3d(-3600px, 0px, 0px)'
                }


            },


            clickVideo() {
                debugger
                this.videoWindowVisible = true
                this.$nextTick(() => {
                    this.$refs.videoWindow.init()
                })

            },
            navChange(type) {
                // 0 时政资讯 1 党建活动 2 党务百科 3联系方式
                if (type == 0) {
                    //时政资讯
                    this.activeName = 'first'
                    this.getNewList(0)
                }
                if (type == 1) {
                    //党建活动
                    this.activeName = 'second'
                    this.galleryThumbsLunbo();
                    this.galleryTopLunbo();
                }
                if (type == 2) {
                    //党务百科
                    this.activeName = 'third'
                    this.getNewList(2)
                }
                if (type == 3) {
                    // 联系我们
                    this.activeName = 'fourth'
                }
                debugger
                document.getElementById("dj_nav").scrollIntoView();

            },
            galleryThumbsLunbo() {
                this.galleryThumbs = new Swiper('.gallery-thumbs1', {
                    spaceBetween: 0, //在slide之间设置距离（单位px）
                    slidesPerView: 1, //设置slider容器能够同时显示的slides数量
                    loop: true, //设置为true 则开启loop模式
                    freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
                    loopedSlides: 2, //一般设置大于可视slide个数2个即可
                    watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
                    watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
                });
            },
            galleryTopLunbo() {
                this.galleryTop = new Swiper('.gallery-top1', {
                    spaceBetween: 0,
                    slidesPerView: 3,
                    loop: true,
                    centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
                    loopedSlides: 3,
                    // 左右按钮
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    thumbs: { //thumbs组件专门用于制作带缩略图的swiper
                        swiper: this.galleryThumbs
                    },
                });
            },




            tableLocal() {
                debugger
                if (this.$route.params.type) {
                    if (this.$route.params.type == 0) {
                        //时政资讯
                        this.activeName = 'first'
                        this.getNewList(0)
                    }
                    if (this.$route.params.type == 1) {
                        //党建活动
                        this.activeName = 'second'
                        this.galleryThumbsLunbo();
                        this.galleryTopLunbo();
                    }
                    if (this.$route.params.type == 2) {
                        //党务百科
                        this.activeName = 'third'
                        this.getNewList(2)
                    }
                    if (this.$route.params.type == 3) {
                        // 联系我们
                        this.activeName = 'fourth'
                    }




                } else {
                    this.activeName = 'first'
                    window.scrollTo(0, 0);
                    this.getNewList(0)
                    // debugger
                    // console.log()

                    // if (localStorage.getItem('task') !== null) {
                    //     this.activeName = localStorage.getItem('task')
                    // } else {
                  
                    // }
                    // if (localStorage.getItem('index') !== null) {
                    //     this.getNewList(localStorage.getItem('index'))
                    // } else {
                    //     this.getNewList(0)
                    // }
                }


            },
            // 分页, 每页条数
            pageSizeChangeHandle(val) {
                this.page = 1
                this.limit = val
                this.getNewList(0)
            },
            // 分页, 当前页
            pageCurrentChangeHandle(val) {
                this.page = val
                this.getNewList(0)
            },
            handleClick(tab, event) {
                let index = tab.index
                this.getNewList(index)
                localStorage.setItem('task', tab.name)
                localStorage.setItem('index', tab.index)
            },
            //跳转详情页
            goToDetail(id, type) {
                this.$router.push({
                    path: '/partyDetail',
                    query: {
                        id,
                        type
                    }
                })
            },
            //党务百科title查询
            getDwbk() {
                this.$http.get(`/attract_investment/front/show/newsList`, {
                    params: {
                        page: 1,
                        limit: 1000,
                        categoryId: '1612641248077438978',
                        title: this.dwbkTitle
                    }
                }).then(({
                    data: res
                }) => {
                    this.dwbkList = res.list
                })
            },
            //获取时政资讯
            getNewList(type) {
                let data
                if (type == 0) {
                    //时政资讯
                    data = {
                        page: this.page,
                        limit: this.limit,
                        categoryId: '1612641078497533953'
                    }
                }
                if (type == 1) {
                    // 党建活动
                    // data = {
                    //     page: 1,
                    //     limit: 1000,
                    //     categoryId: '1612641151541338113'
                    // }
                    this.$forceUpdate()
                }
                if (type == 2) {
                    //党务百科
                    data = {

                        page: 1,
                        limit: 1000,
                        categoryId: '1612641248077438978'
                    }
                }


                this.$http.get(`/attract_investment/front/show/newsList`, {
                    params: {
                        ...data
                    }
                }).then(({
                    data: res
                }) => {
                    if (type == 0) {
                        this.total = res.total
                        this.szzxList = res.list
                    }
                    // if (type == 1) {
                    //     this.djhdIndex = res.list[0].title
                    //     this.djhdList = res.list

                    // }
                    if (type == 2) {
                        this.dwbkList = res.list
                    }

                })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .videoStop {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
        display: none;

        img {
            margin-top: 280px;
        }
    }

    .djhd_on {
        height: 1000px;
        padding: 150px 0;
    }

    .djhd_off {
        height: 0;
        padding: 0 !important;
    }

    .siper_video:hover {
        .videoStop {
            display: block;
        }
    }

    .siper_video {
        position: relative;
        cursor: pointer;

        video {
            width: 100%;
        }
    }

    .szzx {
        padding: 150px 0;
        overflow: hidden;
    }

    .banner {
        width: 100%;

        img {
            width: 100%;
            display: block;
        }
    }

    .szzx_item_right {
        width: 268px;
        height: 140px;

        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }

    .szzx_item_nat {
        font-size: 11px;
        color: #454545;

        span {
            color: #999999;
        }
    }

    .szzx_item_txt {
        font-size: 14px;
        color: #333333;
        line-height: 26px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

    }

    .szzx_title {
        font-size: 24px;
        color: #454545;
        text-align: left;
        position: relative;
        margin: 30px 0;

        h3 {
            font-size: 36px;
            margin: 0;
            margin-top: -20px;
        }
    }

    .szzx_title:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 45px;
        height: 5px;
        background: #d4b391;
    }

    .szzx_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 30px 18px;
        background: #fff;
        border: 1px solid #ededed;
        margin: 30px 0;
        cursor: pointer;
    }

    .szzx_item:hover {
        background: #ededed;
    }

    .szzx_item:hover h4 {
        color: #c81422;
    }

    .szzx_item_left {
        width: 70%;
        text-align: left;

        h4 {
            font-size: 18px;
            color: #333333;
            margin: 0;
            margin-bottom: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .lxwm_top {
        background: url(../../assets/img/dq_lxwm_bg.png) center center no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 62px 0;
    }

    .lxwm_top_left {
        font-size: 30px;
        color: #fff;
        width: 45%;

        h3 {
            margin-top: 60px;
        }
    }

    .lxwm_top_right {
        font-size: 14px;
        color: #fff;
        width: 55%;
        text-align: left;
        border-left: 1px solid #fff;
        box-sizing: border-box;
        padding-left: 45px;
    }

    .lxwm_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .lxwm_item:hover {
        background: #f2ede2;
        box-shadow: 1px 0 24px rgba(0, 0, 0, .13);
    }

    .lxwm_item {
        width: 47%;
        border: 1px solid #d4bb8e;
        box-sizing: border-box;
        padding: 40px;
        margin: 25px 0;

        h3 {
            font-size: 30px;
            color: #333333;
            text-align: left;
            margin: 0;
        }
    }

    .lxwm_item_text {
        font-size: 14px;
        color: #333333;
        text-align: left;
        margin-top: 35px;

        p {
            margin: 0;
            margin-bottom: 18px;
        }
    }

    .lxwm_item_text p:last-child {
        margin-bottom: 0;
    }

    .submit_bo_fdj {
        position: relative;
    }

    .fdj_box {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        width: 60px;
        height: 40px;
        text-align: center;
        line-height: 44px;

    }

    .dwbk_item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 22px 0 22px 48px;
        border-bottom: 1px solid #e9e9e9;
        cursor: pointer;

        h3 {
            width: 100%;
            text-align: left;
            font-size: 20px;
            color: #676767;
            margin: 0;
            box-sizing: border-box;
            padding: 10px 0 10px 30px;
            border-radius: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .dwbk_item:after {

        position: absolute;
        content: '';
        left: 47px;
        top: 28px;
        height: 33px;
        width: 1px;
        background: #c9c9c9;
    }

    .dwbk_list {
        width: 70%;
        float: right;
    }


    .dwbk_item:hover::after {
        background: #c81422;
        width: 4px;
    }

    .dwbk_item_pic {
        display: none;
        position: absolute;
        right: 20px;
        top: 35px;
    }

    .dwbk_item:hover {
        h3 {
            color: #c81422;
            background: #fec88e;
        }

        .dwbk_item_pic {
            display: block;
        }
    }

    .szzx_list {
        margin-top: 62px;
    }

    .other h3 {
        padding-bottom: 13px;
    }
</style>
<style lang="scss">
    .djhd_swiper {
        margin-top: 80px;
        position: relative;

        .gallery-thumbs1 {
            margin-top: 50px;
        }

        .gallery-top1 {
            border-bottom: 1px solid #f2f2f2;

            .swiper-slide-active {
                color: #ab1621 !important;
                font-size: 25px !important;
                border-bottom: 3px solid #ab1621;
                margin-top: -5px;

            }

            .swiper-slide {
                font-size: 18px;
                color: #a7a7a7;
                padding: 15px 0 20px;
            }
        }


        .swiper_pic {
            img {
                width: 100%;
            }
        }

        .swiper-button-next {
            background: url(../../assets/img/cyfzzx_right.png) center center no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 20px;
            top: 40px;
            right: -20px;

            i {
                display: none;
            }
        }

        .swiper-button-next:after {
            content: '';
        }

        .swiper-button-prev {
            background: url(../../assets/img/cyfzzx_left.png) center center no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 20px;
            top: 40px;
            left: -20px;

            i {
                display: none;
            }
        }

        .swiper-button-prev:after {
            content: '';
        }

    }

    .dwbk_nav {
        .el-tabs__nav {
            width: 100%;
        }

        .el-tabs__item {
            padding: 0 162px;
        }
    }

    .submit_bo_fdj {
        input {
            border-radius: 10px;
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
            color: #d4b391;
        }

        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
            color: #d4b391;
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            color: #d4b391;
        }
    }

    .djhd_box {
        .el-tabs__nav {
            width: 50%;
        }
    }
</style>