<template>
    <div class="new">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/new_banner.jpg">
        </div>
        <div id="zzz"></div>
        <div class="new_nav">
            <div class="all_box">
                <div class="new_nav_box" id="new_nav">
                    <ul>
                        <li @click="getNewList(1)" :class="oneGao">行业动态</li>
                        <li @click="getNewList(2)" :class="twoGao">邦芒新闻</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="new_box">
            <div class="all_box">
                <div class="hot_new">
                    <div class="hot_new_title">
                        <h3>热点推荐</h3>
                        <p v-if='type == 1'>洞察行业趋势，解读行业案例</p>
                        <p v-if='type == 2'>前沿邦芒资讯，最新邦芒动态</p>
                    </div>
                    <div class="hot_new_list">
                        <div class="hot_new_item" v-for="item in hotList" @click="gotoNewDetail(item.id)">
                            <div class="hot_new_pic">
                                <img :src="item.imageUrl">
                            </div>
                            <div class="hot_new_item_text">
                                <h3>
                                    {{ item.title }}</h3>
                                <h4>{{ item.releaseTime }}</h4>
                                <div class="hot_p" v-html="item.summary"></div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="new_zw_list">
                    <div class="new_zw_item" @click="gotoNewDetail(item.id)" v-for="(item, index) in newList">
                        <div class="new_zw_time">
                            <h3>{{ [...item.createDate.matchAll(/(\d{4})-(\d{2})-(\d{2})/g)][0][3] }}</h3>
                            <p>{{ [...item.createDate.matchAll(/(\d{4})-(\d{2})-(\d{2})/g)][0][1] }}/{{ [...item.createDate.matchAll(/(\d{4})-(\d{2})-(\d{2})/g)][0][2] }}
                            </p>
                        </div>
                        <div class="new_zw_text">
                            <h3><img src="@/assets/img/new_hot.png"
                                    v-if="index == 0 || index == 1 && page == 1">{{ item.title }}</h3>
                            <div class="new_zw_p" v-html="item.summary"></div>
                        </div>
                    </div>
                </div>
                <div class="fenye_box">
                    <el-pagination :current-page="page" :page-sizes="[5, 10, 20, 50, 100]" background :page-size="limit"
                        :total="total" layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChangeHandle"
                        @current-change="pageCurrentChangeHandle">
                    </el-pagination>
                </div>

            </div>
        </div>
        <foots @navChange="navChange"></foots>
    </div>
</template>
<script>
import heads from '@/components/head/index.vue'
import foots from '@/components/foot/index.vue'
export default {
    name: 'new',
    data() {
        return {
            oneGao: '',
            twoGao: '',
            type: '',
            page: 1,
            limit: 10,
            total: 0,
            newList: [],
            hotList: []
        }
    },
    components: {
        heads,
        foots
    },
    mounted() {

        if (this.$route.params.type) {
            this.getNewList(this.$route.params.type)
            document.getElementById("zzz").scrollIntoView();
        } else {
            this.tableLocal()
        }

        this.getHotList(1)
    },
    methods: {
        navChange(type) {
            this.getHotList(type)
            this.getNewList(type)
            document.getElementById("zzz").scrollIntoView();
        },
        // 分页, 每页条数
        pageSizeChangeHandle(val) {
            this.page = 1
            this.limit = val
            this.getNewList(this.type)
        },
        // 分页, 当前页
        pageCurrentChangeHandle(val) {
            this.page = val
            this.getNewList(this.type)
        },
        // 获取热点市场动态列表
        getHotList(type) {
            if (type == 1) {
                let data = {
                    page: 1,
                    limit: 3,
                    categoryId: '1612993825084301313',
                    orderField:"release_time",
                    order:"desc"
                }
                this.$http.get(`/attract_investment/front/show/newsList`, {
                    params: {
                        ...data
                    }
                }).then(({
                    data: res
                }) => {
                    this.hotList = res.list
                })
            }
            if (type == 2) {
                let data = {
                    page: 1,
                    limit: 3,
                    categoryId: '1612993917329629186',
                    orderField:"release_time",
                    order:"desc"
                }
                this.$http.get(`/attract_investment/front/show/newsList`, {
                    params: {
                        ...data
                    }
                }).then(({
                    data: res
                }) => {
                    this.hotList = res.list
                })
            }

        },
        tableLocal() {

            if (localStorage.getItem('newindex') !== null) {
                this.getNewList(localStorage.getItem('newindex'))
            } else {
                this.getNewList(1)
            }
        },
        // 获取市场动态列表
        getNewList(type) {
            this.getHotList(type)
            this.type = type
            let data = {}
            if (type == 1) {
                //市场动态
                data = {
                    page: this.page,
                    limit: this.limit,
                    //市场动态
                    categoryId: '1612993825084301313',
                    orderField:"release_time",
                    order:"desc"
                }
                this.oneGao = 'nav_gao'
                this.twoGao = ''
            }
            if (type == 2) {
                //邦芒新闻
                data = {
                    page: this.page,
                    limit: this.limit,
                    //市场动态
                    categoryId: '1612993917329629186',
                    orderField:"release_time",
                    order:"desc"
                }
                this.oneGao = ''
                this.twoGao = 'nav_gao'
            }
            this.$http.get(`/attract_investment/front/show/newsList`, {
                params: {
                    ...data
                }
            }).then(({
                data: res
            }) => {
                this.total = res.total
                this.newList = res.list
            })
        },

        //跳转新闻详情页
        gotoNewDetail(id) {
            this.$router.push({
                path: '/NewsListBM/Detail/',
                query: {
                    id,
                    type: this.type
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.hot_new_pic img {
    width: 100%;
}

.new_box {
    padding-top: 115px;
}

.new_zw_item {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-bottom: 1px solid #dddddd;
    padding: 38px 0;
}

.new_zw_item:hover {
    background: #ededed;
}

.new_zw_text {
    text-align: left;
    padding-left: 22px;
    box-sizing: border-box;
    width: 93%;

    img {
        width: 20px;
        margin-right: 10px;
        margin-bottom: -5px;
    }

    h3 {
        font-size: 16px;
        color: #333333;
        margin: 12px 0 0;
    }

    .new_zw_p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        margin-top: 10px;
    }
}

.new_zw_time {
    width: 83px;
    height: 83px;
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 17px;

    h3 {
        font-size: 24px;
        color: #787878;
        margin: 0;
    }

    p {
        font-size: 14px;
        color: #787878;
        margin: 0;
    }
}

.banner {
    width: 100%;

    img {
        width: 100%;
        display: block;
    }
}

.hot_new_title {
    position: relative;
    text-align: left;
    padding-bottom: 38px;
    margin-bottom: 45px;

    h3 {
        font-size: 36px;
        color: #454545;
        margin: 0;
        margin-bottom: 24px;
    }

    p {
        font-size: 16px;
        color: #454545;
        margin: 0;
    }

    :after {
        content: '';
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        position: absolute;
        background: #dddddd;
    }

    ::before {
        content: '';
        width: 80px;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #dab28e;
    }
}

.fenye_box {
    padding: 60px 0 100px;
}

.hot_new {
    padding-bottom: 70px;
}

.hot_new_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .hot_new_item {
        border: 2px solid #dddddd;
        margin-left: 36px;
        width: 31%;
        cursor: pointer;

        .hot_new_pic {
            height: 250px;

            img {
                height: 100%;
            }
        }
    }

    .hot_new_item:first-child {
        margin-left: 0;
    }
}

.hot_new_item_text {
    text-align: left;
    box-sizing: border-box;
    padding: 30px 20px;

    h3 {
        font-size: 16px;
        color: #333333;
        margin: 0;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        height: 40px;
    }

    h4 {
        font-size: 14px;
        color: #666666;
        margin: 0;
    }

    .hot_p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
    }
}

.new_nav {
    background: #ededed;
    padding: 30px 0;
}

.new_nav_box {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            margin-left: 158px;
            cursor: pointer;
        }

        li:first-child {
            margin-left: 0;
        }
    }

}

.nav_gao {
    position: relative;
}

.nav_gao:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
    height: 4px;
    background: #c81422;
}
</style>
<style lang="scss">
.fenye_box {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #c81422;
        color: #FFF;
    }

    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #c81422;
    }
}
</style>