
import JSZip from 'jszip'
import axios from "axios"
// 先封装一个方法，请求返回文件blob
export async function fetchBlob (fetchUrl, method = "get", body = null) {
 const response = await axios[method](fetchUrl, {
  params: body,
  responseType: 'blob'
 });
 const blob = response.data
 return [blob, response.headers['content-type']];
}
export async function genZipUrl (fileList) {
 const jszip = new JSZip()
 for (let k = 0; k < fileList.length; k++) {
   const blob = await fetchBlob(fileList[k].url, 'get')
  //  console.log(blob)
   jszip.file(fileList[k].name, blob); // 支持Promise类型，需要返回数据类型是 String, Blob, ArrayBuffer, etc
 }
 const blob = await jszip.generateAsync({ type: "blob" })
 return window.URL.createObjectURL(blob)
}
export function downloadUrl(url,name) {
  let filename = name
  let downloadElement = document.createElement('a');
  downloadElement.style.display = 'none';
  downloadElement.href = url;
  downloadElement.download = filename; //下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
}