<template>
    <div>
        {{leftNumber}}
        <div class="tab-list" id="nav">
            <div class="list_box" :style="{left:leftNumber+'px'}">
                <div class="tab-item" v-for="(item,index) in list" :key="item">{{item}}</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                leftNumber: 0,
                list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
        },
        mounted() {
            this.scrollInit();
        },
        methods: {
            scrollInit() {
                let _this = this
                const nav = document.getElementById("nav")
                var flag; // 鼠标按下
                var downX; // 鼠标点击的x下标
                var scrollLeft; // 当前元素滚动条的偏移量
                let nowIndex;//当前坐标

                nav.addEventListener("mousedown", function (event) {
                    console.log(1)
                    flag = true;
                    downX = event.clientX; // 获取到点击的x下标
                    scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
                    nowIndex = _this.leftNumber
                });
                nav.addEventListener("mousemove", function (event) {
                    console.log(2)
                    if (flag) { // 判断是否是鼠标按下滚动元素区域
                        var moveX = event.clientX; // 获取移动的x轴
                        var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
                        this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
                        if (scrollX < 0) {
                            _this.leftNumber = nowIndex - Math.abs(scrollX);
                        } else {
                            _this.leftNumber = nowIndex + Math.abs(scrollX);

                        }
                        _this.$forceUpdate();
                    }
                });
                // 鼠标抬起停止拖动
                nav.addEventListener("mouseup", function () {
                    console.log(3)
                    this.leftNumber = nowIndex
                    flag = false;
                });
                // 鼠标离开元素停止拖动
                nav.addEventListener("mouseleave", function (event) {
                    console.log(4)
                    flag = false;
                });

            }
        }
    }
</script>
<style lang="scss" scope>
    #nav {
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;

    }

    .list_box {
        position: absolute;
        left: 0;
        top: 0;
    }

    .tab-item {
        display: inline-block;
        width: 200px;
        height: 100px;
        background-color: red;
        text-align: center;
    }
</style>