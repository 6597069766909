<template>
    <div class="foot">
        <div class="foot_top">
            <div class="all_box foot_top_body">
                <div class="foot_left">
                    <img src="@/assets/img/foot_logo.png">
                    <div class="phone_list">
                        <div class="phone_item">
                            <div class="phone_img">
                                <img src="@/assets/img/foot_pic01.png">
                            </div>
                            <div class=" phone_text">
                                <h4>服务热线：</h4>
                                <p class="big">400-891-5050</p>
                            </div>
                        </div>
                        <div class="phone_item">
                            <div class="phone_img">
                                <img src="@/assets/img/foot_pic02.png">
                            </div>
                            <div class=" phone_text">
                                <h4>意见与投诉:</h4>
                                <p>0573-82722090</p>
                            </div>
                        </div>
                        <div class="phone_item">
                            <div class="phone_img">
                                <img src="@/assets/img/foot_pic03.png">
                            </div>
                            <div class=" phone_text">
                                <h4>商务合作：</h4>
                                <p>yy@50bm.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="share">
                        <div class="erweima_box" v-if="erweimaHidden">
                            <img src="@/assets/img/bm_erweima.jpg">
                        </div>
                        <div class="share_list">
                            <div class="share_item">
                                <img src="@/assets/img/microBlog.png">
                            </div>
                            <div class="share_item">
                                <img src="@/assets/img/weChat.png" @mouseover="aaa()" @mouseout="bbb()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foot_right">
                    <div class="foot_nav_list">
                        <div class="foot_nav_shu">
                            <p class="one_nav">行业解决方案</p>
                            <!-- <p class="one_nav" @click="jumpNav('business',0)">行业解决方案</p> -->
                            <p @click="jumpNav('business',1)">人事服务外包HRO</p>
                            <p @click="jumpNav('business',2)">业务流程外包BPO</p>
                            <p @click="jumpNav('business',3)">招聘服务外包RPO</p>
                            <!-- <p>技术服务外包ITO</p> -->
                            <p @click="jumpNav('business',4)">劳务派遣</p>
                            <p @click="jumpNav('business',5)">短期用工</p>
                            <p @click="jumpNav('business',6)">HR SaaS</p>
                            <!-- <p>园区运营</p> -->
                        </div>
                        <div class="foot_nav_shu">
                            <!-- <p class="one_nav"><a href="http://cn.50bm.com/">产业发展中心</a></p> -->
                            <p class="one_nav">产业发展中心</p>
                            <p @click="jumpNav('industry',1)">有余坊</p>
                            <p><a href="https://www.pinyee.net/" target="_blank">聘也</a></p>
                            <!-- <p><a href="http://qm.50bm.com/" target="blank">青芒计划</a></p> -->
                            <p><a href="http://www.onhelp.cn/" target="_blank">富能会计</a></p>
                            <!-- <p><a href="http://www.richtoo.cn/" target="_blank">富能会计</a></p> -->
                            <!-- <p><a href="http://www.baowei114.com/" target="_blank">劳博士</a></p> -->
                            <p><a href="http://www.50bmzs.com/" target="_blank">邦芒招商</a></p>
                            <p @click="jumpNav('industry',2)">产业园区</p>
                            <p><a href="http://www.bmclass.com/" target="_blank">邦芒培训</a></p>
                            <p><a href="http://www.rlbsh.com/" target="_blank">亲友帮</a></p>
                            <p><a href="https://www.renshibao.com/" target="_blank">人事宝科技</a></p>
                            <p><a href="http://www.kongyushang.com/" target="_blank">空余上</a></p>
                        </div>
                        <div class="foot_nav_shu">
                            <!-- <p class="one_nav" @click="jumpNav('new',1)">新闻资讯</p> -->
                            <p class="one_nav">新闻资讯</p>
                            <p @click="jumpNav('new',1)">行业动态</p>
                            <p @click="jumpNav('new',2)">邦芒新闻</p>
                        </div>
                        <div class="foot_nav_shu">
                            <!-- <p class="one_nav" @click="jumpNav('responsibility')">社会责任</p> -->
                            <p class="one_nav">社会责任</p>
                            <p @click="jumpNav('responsibility')">公益活动</p>
                        </div>
                        <div class="foot_nav_shu">
                            <!-- <p class="one_nav" @click="jumpNav('party',0)">党群中心</p> -->
                            <p class="one_nav">党群中心</p>
                            <p @click="jumpNav('party',1)">党建活动</p>
                            <p @click="jumpNav('party',0)">时政资讯</p>
                            <p @click="jumpNav('party',2)">党务百科</p>
                            <p @click="jumpNav('party',3)">联系方式</p>
                        </div>
                        <div class="foot_nav_shu">
                            <!-- <p class="one_nav" @click="jumpNav('about',0)">关于我们</p> -->
                            <p class="one_nav">关于我们</p>
                            <p @click="jumpNav('about',1)">了解邦芒</p>
                            <p @click="jumpNav('about',2)">发展大事记</p>
                            <p @click="jumpNav('about',3)">荣誉墙</p>
                            <p><a href="http://qm.50bm.com/" target="blank">青芒计划</a></p>
                            <p @click="jumpNav('about',4)">企业文化</p>
                            <p @click="jumpNav('contact',2)">网点查询</p>
                            <p @click="jumpNav('contact',1)">加入邦芒</p>
                            <!-- <p @click="jumpNav('contact')">联系我们</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot_yejiao">
            <p>上海总部地址：上海市闵行区永德路795号邦芒总部大厦 | 电话：400-891-5050 | 邮编：201100</p>
            <p>版权所有：邦芒服务外包有限公司 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index"
                    target="blank">浙ICP备19006763号-3</a></p>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                erweimaHidden:false,
            }
        },
        methods: {

            aaa(){
              this.erweimaHidden = true
            },
            bbb(){
                this.erweimaHidden = false
            },

            jumpNav(item, type) {
                let routeName = this.$route.name
                if (routeName == item) {
                    this.$emit('navChange', type)
                } else {
                    this.$router.push({
                        name: item,
                        params: {
                            type
                        }
                    });
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    a {
        text-decoration: none;
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .phone_list {
        margin-top: 45px;
    }

    .phone_list .phone_item:last-child {
        padding-bottom: 0;
    }

    .phone_item {
        padding-bottom: 50px;
    }

    .foot_top {
        background: linear-gradient(to right, #ab1621 40%, #272727 40%);
        padding: 48px 0 32px;
    }

    .foot_top_body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .foot_left {
        width: 40%;
        text-align: left;
    }

    .foot_right {
        width: 60%;
    }

    .phone_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .phone_text h4 {
        margin: 0;
        font-size: 18px;
    }

    .phone_text p {
        margin: 0;
        font-size: 24px;
        margin-top: 15px;
    }

    .phone_text .big {
        font-size: 30px;
    }

    .share_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .share_list .share_item {
        margin-left: 38px;
    }

    .share_list .share_item:first-child {
        margin-left: 0;
    }

    .share {
        margin-top: 40px;
        position: relative;
    }

    .erweima_box {
        position: absolute;
        left: 98px;
        top: -114px;
        width: 110px;

        img {
            width: 100%;
        }
    }

    .phone_text {
        color: #fff;
        margin-left: 17px;
    }

    .foot_nav_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        text-align: left;
    }

    .one_nav {
        font-size: 18px ! important;
        color: #fff;
        position: relative;
        padding-bottom: 17px;
        margin: 17px 0 !important;
    }

    .foot_nav_shu p {
        font-size: 11px;
        color: #fff;
        margin: 14px 0;
        cursor: pointer;

        a {
            color: #fff;
        }
    }

    .foot_nav_shu {
        margin-left: 42px;
    }

    .foot_nav_shu:first-child {
        margin-left: 0;
    }

    .one_nav::after {
        content: '';
        position: absolute;
        left: 0;
        top: 40px;
        width: 40px;
        height: 2px;
        background: #fff;
    }

    .foot_yejiao {
        text-align: center;
        font-size: 14px;
        color: #767676;
        background: #3a3a3a;
    }

    .foot_yejiao p {
        margin: 0;
        line-height: 30px;
    }

    .foot_yejiao {
        padding: 50px 0;

        a {
            color: #767676;
        }
    }
</style>