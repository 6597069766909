<template>
    <div class="topBtn">
        <!-- 置顶按钮 -->
        <div class="zhiding" ref="zhiding" @click="zhidingClick()">
            <img src="@/assets/img/top_on.png" class="top_on">
            <img src="@/assets/img/top_off.png" class="top_off">
        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                data:''
            }
        },
        
        mounted() {
           
            // 向页面添加股东事件
            window.addEventListener('scroll', this.handleScroll, true);
            // this.handleScroll()
        },
        destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
            window.removeEventListener('scroll', this.handleScroll)
        },

        methods: {
            //点击置顶按钮
            zhidingClick() {
                console.log(this.data)

                this.data.scrollIntoView({
                    behavior: 'smooth', //顺滑的滚动
                })
            },

            //滚动事件
            handleScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop > 0) {
                    this.$refs.zhiding.classList.value = 'zhiding zhiding_on'
                } else {
                    this.$refs.zhiding.classList.value = 'zhiding zhiding_off'
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .zhiding {
        z-index: 10000;
        position: fixed;
        cursor: pointer;
        right: 50px;
        bottom: -80px;
        width: 62px;
        height: 62px;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
        }

        .top_on {
            display: none;
        }
    }

    .zhiding_off {
        bottom: -80px;
        transition-property: bottom;
        transition-duration: 1s;
    }

    .zhiding_on {
        bottom: 50px;
        transition-property: bottom;
        transition-duration: 1s;
    }

    .zhiding:hover {
        .top_on {
            display: block;
        }

        .top_off {
            display: none;
        }
    }
</style>