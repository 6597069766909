<template>
    <div class="partyDetail">
        <topBtn ref="topBtn"></topBtn>
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/new_banner.jpg">
        </div>
        <div id="zzz"></div>
        <div ref="zzz"></div>
        <div class="nei_nav">
            <div class="all_box">
                <div class="nei_nav_box">
                    <ul>
                        <li :class="nav_one" @click="gotoParty(1)">行业动态</li>
                        <li :class="nav_two" @click="gotoParty(2)">邦芒新闻</li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="all_box">
            <div class="detail">
                <h3>{{dataForm.title}}</h3>
                <div class="time">
                    发布时间：{{dataForm.createDate}}
                </div>
                <div class="detail_text" v-html="dataForm.content">
                </div>
                <div class="laiyuan">来源：{{dataForm.source}}</div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>
<script>
    import partyBanner from '@/assets/img/party_banner.png'
    import newBanner from '@/assets/img/new_banner.png'
    import topBtn from '@/components/topBtn/index.vue'
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        data() {
            return {
                id: '',
                dataForm: {}
            }
        },
        mounted() {
            this.$refs.topBtn.data = this.$refs.zzz
            if (this.$route.query.id) {
                this.id = this.$route.query.id
            }
            if(this.$route.query.type == 1){
                this.nav_one = 'nav_gl'
            }
            if(this.$route.query.type == 2){
                this.nav_two = 'nav_gl'
            }
            this.getDetail()
            document.getElementById("zzz").scrollIntoView();
        },
        components: {
            heads,
            foots,
            topBtn
        },
        methods: {
            getDetail() {
                this.$http.get(`/attract_investment/front/show/getNewsDetail/${this.id}`).then(({
                    data: res
                }) => {
                    this.dataForm = res
                })
            },
            gotoParty(number) {
                localStorage.setItem('newindex', number)
                this.$router.push({
                    name: 'new'
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .detail_text{
        text-align: initial;
    }
    .nei_nav {
        background: #ededed;
    }
    .banner {
        width: 100%;

        img {
            width: 100%;
            display: block;
        }
    }
    .nav_gl:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: #c81422;
        left: 0;
        bottom: -30px;

    }
    .laiyuan {
        text-align: left;
        font-size: 16px;
        color: #a7a7a7;
        margin-top: 100px;
    }
    .detail{
        padding: 100px 0;
        h3{
            font-size: 28px;
            color:#454545;
            margin-bottom:40px;
        }
    }
    .time{
        text-align: left;
        font-size: 14px;
        color: #a7a7a7;
        margin-bottom: 40px;
    }
    
    .nei_nav_box {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content:flex-start ;
            list-style: none;
            padding: 0;
            margin: 0;
            padding:30px 0;

            li {
                color: #454545;
                font-size: 14px;
                position: relative;
                cursor: pointer;
                margin-left: 158px;
                font-size: 16px;
                color:#454545;
            }
            li:first-child{
                margin-left: 0;
            }
        }
    }
</style>