<template>
    <div class="responsibility">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/shzr_banner.jpg">
        </div>
        <div class="shzr">
            <div class="shzr_one">
                <div class="all_box">
                    <div class="shzr_one_title">
                        <h3>聚焦防疫 共抗时艰</h3>
                        <h4>邦芒公益助力企业复工复产</h4>
                        <div class="henxian"></div>
                        <div class="shzr_one_text">
                            <p>邦芒聚焦社会责任在大环境背景下的防疫战疫新维度，联同社会，共抗时艰。面对疫情，邦芒助力企业复工复产，承办全国首个城市网络招聘节，联合万家企业共同推出百万就业岗位，解决社会就业难题，抗击疫情累计捐款捐物500万元。
                            </p>
                            <p>邦芒凭借着自身专业的人力资源服务经验和先进高效的技术能力，加速推动了企业的复工复产，邦芒一直紧随时代步伐，和全社会一起共同抗疫，度过至暗时刻。未来，邦芒仍会牢记使命，勇往直前。
                            </p>
                        </div>
                        <div class="shzr_pic_list">
                            <div class="shzr_pic_item">
                                <div class="shzr_text">●万岗招聘节启动</div>
                                <img src="@/assets/img/shzr01.png">
                            </div>
                            <div class="shzr_pic_item">
                                <div class="shzr_text">●免费包机返岗</div>
                                <img src="@/assets/img/shzr02.png">
                            </div>
                            <div class="shzr_pic_item">
                                <div class="shzr_text">●邦芒物资捐赠</div>
                                <img src="@/assets/img/shzr03.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shzr_two">
            <el-carousel autoplay="false" interval="6000" trigger="click" height="640px" arrow="always"
                indicator-position="none" class="bigSwiper">
                <el-carousel-item v-for="(item,index) in swiperList" :key="item">
                    <div class="swiper_box">
                        <div class="swiper_left">
                            <h3>{{item.h3}}</h3>
                            <h4>{{item.h4}}</h4>
                            <div class="henxian">

                            </div>
                            <div class="swiper_left_text" v-html="item.p">
                            </div>
                            <div class="swiperIndex">0{{index+1}} / 0{{swiperList.length}}</div>
                        </div>
                        <div class="swiper_right">
                            <el-carousel autoplay="false" trigger="click" interval="2000" height="640px"
                                class="smSwiper" arrow="never">
                                <el-carousel-item v-for="item2 in item.img" :key="item2">
                                    <img :src="item2">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>

        <foots></foots>
    </div>
</template>
<script>
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    import swiper01 from '@/assets/img/swiper01.png'
    import swiper02 from '@/assets/img/swiper02.png'
    import swiper03 from '@/assets/img/swiper03.png'
    import swiper04 from '@/assets/img/swiper04.png'
    import swiper05 from '@/assets/img/swiper05.png'
    import swiper06 from '@/assets/img/swiper06.png'
    import swiper07 from '@/assets/img/swiper07.png'
    import swiper08 from '@/assets/img/swiper08.png'
    import swiper09 from '@/assets/img/swiper09.png'
    import swiper10 from '@/assets/img/swiper10.png'
    import swiper11 from '@/assets/img/swiper11.png'
    import swiper12 from '@/assets/img/swiper12.png'
    import swiper13 from '@/assets/img/swiper13.png'

    import swiper14 from '@/assets/img/swiper14.png'
    import swiper15 from '@/assets/img/swiper15.png'
    import swiper16 from '@/assets/img/swiper16.png'
    export default {
        name: 'responsibility',
        data() {
            return {
                swiperList: [{
                    h3: '奉献爱心 聚力担当',
                    h4: '邦芒组织开展无偿献血公益活动',
                    p: '<p>面对血站血型全线告急、临床用血短缺的严峻形势，邦芒积极响应政府号召，组织开展“奉献爱心，延绵热血”无偿献血活动。公司员工踊跃报名参与，纷纷贡献自己的绵薄之力。此次活动中，既有初次献血的员工，也有多次献血的员工，大家都怀揣着美好的希冀积极配合医务工作人员展开献血活动，场面秩序井然，温馨和谐。这些爱心人士汇聚在一起，用小爱成就大爱，造福整个社会。</p><p>邦芒始终把人文关怀放在企业文化的重要位置上，社会有难，邦芒支援。邦芒人的快乐也从帮助他人上得以彰显，用一颗颗爱心照亮他人前行的道路。未来，邦芒会坚守初心，在服务社会的路上坚定向前。</p>',
                    img: [
                        swiper01, swiper02, swiper03,swiper16
                    ]
                }, {
                    h3: '公益课堂 传递知识',
                    h4: '邦芒在企业、学校等机构开展公益课堂',
                    p: '<p>邦芒开设的公益课堂，为企业管理者提供专业的人力资源管理知识，拓宽管理思维；为员工提供全面的职业技能培训，提升员工的专业技能，培养一技之长；同时为学校的在校学生提供科学合理的职业规划建议，帮助学生从校园过渡到社会，顺畅完成职场人的身份转变。在公益课堂上，邦芒用自身多年的行业经验和丰富的人力资源知识，帮助企业、员工和学生了解行业信息，创造更好的未来。</p><p>邦芒始终认为，一个好的企业一定是能创造出更大社会价值的。因此，邦芒相信，成就他人就是成就自己，坚持用自己的力量帮助社会各行业，不断传递知识，弘扬正能量。</p>',
                    img: [
                        swiper04, swiper05, swiper06
                    ]
                }, {
                    h3: '爱与梦想 跨越山海',
                    h4: '邦芒公益基金守护贵州湄潭留守儿童',
                    p: '<p>在贵州湄潭的大山脚下，有一群条件艰苦的学生，他们大多都是留守儿童。面对这些孩子们原生家庭的困顿和生活学习的不便，邦芒内部228家直营分公司的数千名员工共同参与，为当地的“四点半”学校捐赠电脑、投影仪等教学设备，让孩子们看到更清晰广阔的世界；同时捐赠包含文具、绘画本、彩笔和体育器材的爱心包裹，传递给孩子们满满的爱心与能量。</p><p>邦芒成立公益基金，长期投身于助学公益，传递爱心守护留守儿童，为山区贫困儿童捐款捐物累计500万元；成立邦芒教育基金，大力支持高校人才培养与发展，捐赠累计600万元。</p><p>邦芒始终把社会责任感当做企业的信条，从小业走向大家，邦芒公益基金还将长期持续地投身于助学、助农、助残和环保等各类公益活动中，在社会需要的地方发光发热，传递温暖。</p>',
                    img: [
                        swiper07, swiper08, swiper09
                    ]
                }, {
                    h3: '网格连心 有爱同行',
                    h4: '邦芒爱心筑梦行动仪式顺利开启',
                    p: '<p>为深入开展“守根脉 保平安 办实事”网络大走访，深化街道暖心行动，6月16日上午，街道在人力资源产业园青年之家，举办“网格连心 有爱同行”当湖街道企业暖心筑梦行动启动仪式。</p><p>当湖街道自2019年开始推进暖心行动，通过广泛链接社会资源，搭建奉献爱心、传递真情的平台，为独老孤幼家庭等特殊群体送去爱心，三年多来合计受益人群超过3.5人次。</p><p>在此次网格大走访中，发现一些企业意向加入暖心行动，因此，街道推出企业暖心筑梦行动，鼓励企业除了扶贫济困的输血式帮扶之外，更要依托自身资源，为困难青少年开展造血式帮扶。</p>',
                    img: [
                        swiper10,swiper14,swiper15
                    ]
                }, {
                    h3: '赋能高校，培育青年',
                    p: '<p>邦芒高度重视高校的人才培养。为培育新时代积极向上、勇于创新的青年，邦芒承担起社会责任，联结高校，为在校学生提供丰富的校外资源，倡导大学学子充分利用大学时间，提升自我，夯实基础。</p><p>2021年，邦芒受聘成为上海大学首届卓越班级校外导师；2022年，邦芒开办嘉兴学院微课堂，提供高效、精准的人力资源行业信息支持，加速培养应用型高级人力资源管理人才；受聘成为上海财经大学创业学院创业导师，为在校学子提供前沿的就业创业指导。</p><p>邦芒同各大高校积极开展合作，承担校外导师的责任，给予莘莘学子职业培训和职业发展的积极指导，充分发挥校企联动的育人模式，助推学子们的实践能力稳步提升。未来，邦芒始终致力于践行社会责任，为青年办实事，为青年谋未来。</p>',
                    img: [
                        swiper11, swiper12, swiper13
                    ]
                }, ]
            }
        },
        mounted(){
            window.scrollTo(0,0);    
        },
        components: {
            heads,
            foots
        }
    }
</script>
<style lang="scss" scoped>
    .banner {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .el-carousel__item {
        background: #f8f8f8;
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .shzr_one_title {
        text-align: left;

        h3 {
            font-size: 36px;
            color: #454545;
            margin-top:110px;
        }

        h4 {
            font-size: 24px;
            color: #454545;
            font-weight: 400;
        }
    }

    .henxian {
        width: 100%;
        height: 2px;
        background: #dad9d9;
        position: relative;

    }

    .henxian::before {
        content: '';
        width: 64px;
        height: 14px;
        background: #de0d1e;
        position: absolute;
        left: 0;
        top: -6px;
    }

    .shzr_one_text {
        font-size: 16px;
        color: #454545;
        line-height: 26px;
        margin: 30px 0;
    }

    .shzr_pic_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .swiper_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 640px;
    }

    .shzr_pic_item {
        width: 33%;
        text-align: center;
        position: relative;
    }

    .swiper_left {
        padding-top: 75px;
        text-align: left;
        width: 50%;
        box-sizing: border-box;
        padding-left: 20%;
        padding-right: 95px;

        h3 {
            font-size: 36px;
            color: #454545;
            margin: 0;
            margin-bottom: 35px;
        }

        h4 {
            font-size: 24px;
            color: #454545;
            margin: 0;
            margin-bottom: 20px;
            font-weight: 400;
        }

        .swiper_left_text {
            font-size: 14px;
            color: #454545;
            line-height: 26px;
            margin-top: 25px;

            p {
                margin: 20px 0;
            }
        }
    }

    .swiper_right {
        width: 50%;

    }

    .shzr_two {
        padding-bottom: 180px;
        padding-top: 140px;
    }

    .shzr_text {
        font-size: 14px;
        color: #404040;
        background: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px;
    }

    .swiperIndex {
        position: absolute;
        left: 23.8%;
        top: 88.5%;
    }
</style>
<style lang="scss">
    .bigSwiper {
        .el-carousel__arrow--left {
            left: 20%;
            top: 90%;
            background: url(../../assets/img/shzr_left.png) center center no-repeat;

            i {
                display: none;
            }
        }
        .el-carousel__arrow:hover{
            background-color: initial !important;
        }

        .el-carousel__arrow--right {
            top: 90%;
            right: 70%;
            background: url(../../assets/img/shzr_right.png) center center no-repeat;

            i {
                display: none;
            }
        }
        .el-carousel__indicator--horizontal{
            button{
                width: 10px;
                height:10px;
                border-radius: 10px;
            }
            
        }
        .el-carousel__indicators--horizontal{
            bottom: 8%;
            left: 5%;
        }
    }
    .swiper_right{
        .el-carousel__indicator--horizontal{
            padding: 12px 9px;
        }
    }
</style>