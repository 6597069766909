<template>
    <div class="logo_box" :class="type">
        <el-tabs v-model="logotab">
            <el-tab-pane v-for="(item, index) in datalist" :key="index" :label="item.name" :name="item.name">
                <div class="logo_list">
                    <div v-for="(item1, index1) in item.list" :key="index1" class="logo_item">
                        <img :src="item1">
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    props: {
        type: String
    },
    data () {
        return {
            logotab: 'first',

            datalist: [],
        }
    },
    mounted () {
        this.datalist = []
        const pred = [['合作客户-地产物业', 20],
        ['合作客户-餐饮•团餐行业', 20],
        ['合作客户-互联网行业', 13],
        ['合作客户-IT行业', 19],
        ['合作客户-汽车•燃油行业', 15],
        ['合作客户-食品饮料', 20],
        ['合作客户-物流行业', 15],
        ['合作客户-新能源•制造行业', 15],
        ['合作客户-连锁零售行业', 15],
        ['合作客户-服装饰品行业', 15],
        ['合作客户-酒店行业', 15],
        ['合作客户-金融行业', 20],
        ['合作客户-政府国企', 15, n => `政府国企 (${n}).jpg`],
        ['合作客户-外资企业', 20],
        ]

        pred.forEach(item => {
            let list = []
            for (let k = 1; k <= item[1]; k++) {
                let _t = `${k}.jpg`
                if (typeof item[2] === 'function') {
                    _t = item[2](k)
                }
                list.push(`/官网更新图集/${item[0]}/${_t}`)
            }
            this.datalist.push({
                name: item[0].replace("合作客户-", "").replace("合租客户-", ""),
                list: list
            })
        })

        this.logotab = this.datalist[0].name
    }
}
</script>
<style lang="scss" scoped>

.logo_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.logo_item {
    width: 18.4%;
    margin-right: 24px;
    border: 1px dashed #666666;
    box-sizing: border-box;
    height: 120px;
    overflow: hidden;
    margin-top: 23px;
    background-color: #fff;
}

.logo_list .logo_item:nth-child(5n+5) {
    margin-right: 0;
}

.logo_item img {
    width: 90%;
    // transform: scale(1.1);
    margin-left: 5%;
}

.el-tabs  :deep(.el-tabs__nav) {
    white-space: normal;
    text-align: left;
}

.el-tabs.el-tabs--top  :deep( .el-tabs__item.is-top:nth-child(2)) {
    padding-left: 20px;
}
</style>
<style lang="scss">
.about_son {
    .el-tabs__nav {
        text-align: left !important;
    }
}
</style>