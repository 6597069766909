<template>
    <div class="contact">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/contact_banner.png">
        </div>
        <div id="zzz"></div>
        <div class="contact_box">
            <div class="contact_form">
                <div class="all_box">
                    <div class="contact_title">
                        <h3>联系我们</h3>
                        <div class="henxian"></div>
                    </div>
                    <div class="contact_form_box">
                        <div class="contact_left">
                            <p>邦芒人力上海总部</p>
                            <p>地址：上海市闵行区永德路795号邦芒总部大厦</p>
                            <p>服务热线：400-891-5050</p>
                            <p>商务合作：yy@50bm.com</p>
                            <el-button type="text" link @click="downloadBook" style="display: block;cursor: pointer; margin-bottom: 30px; " >员工手册</el-button>
                            <div class="contact_left_img_box">
                                <div class="contact_img_box_item">
                                    <img src="@/assets/img/erweima.jpg">
                                </div>
                                <!-- <div class="contact_img_box_item">
                                    <img src="@/assets/img/erweima.jpg">
                                </div> -->
                            </div>
                        </div>
                        <div class="contact_right">
                            <el-form :model="dataForm" :rules="dataRule" ref="dataForm"
                                @keyup.enter.native="dataFormSubmitHandle()">
                                <el-form-item prop="contacts">
                                    <el-input v-model="dataForm.contacts" placeholder="姓名"></el-input>
                                </el-form-item>
                                <el-form-item prop="phone">
                                    <el-input v-model="dataForm.phone" placeholder="电话"></el-input>
                                </el-form-item>
                                <el-form-item prop="msg">
                                    <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
                                </el-form-item>
                                <el-form-item prop="msg">
                                    <el-input type="textarea" :rows="8" placeholder="内容" v-model="dataForm.msg">
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary" class="tijiao" @click="formSubmit">提交</el-button>
                        </div>
                    </div>

                    <div id="aaa"></div>

                    <div class="netWork" id="netWork">
                        <div class="netWork_title">
                            <img src="@/assets/img/contact_add.png">
                            <h3>全国网点查询<span>| 联系邦芒</span></h3>
                        </div>
                        <div class="select_box">
                            <el-row>
                                <el-col :span="12" style="padding: 10px;">
                                    <el-select v-model="ssq.code" style="width: 100%;" placeholder="省份" filterable
                                        @change="getQList()">
                                        <el-option v-for="item in options.sList" :key="item.code" :label="item.name"
                                            :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="12" style="padding: 10px;">
                                    <el-select v-model="sCode" style="width: 100%;" placeholder="城市" filterable @change="getFgs">
                                        <el-option v-for="item in options.qList" :key="item.code" :label="item.name"
                                            :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="fgs_list">
                            <el-row>
                                <el-col :span="6" v-for="item in fgs" class="fgs_item_box">
                                    <div class="fgs_item">
                                        <div class="fgs_title">
                                            {{item.compname}}
                                        </div>
                                        <div class="fgs_body">
                                            <p>电话：{{item.comptel}}</p>
                                            <p>地址：{{item.compaddr}}</p>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>


                    <div class="map_box">
                        <img src="@/assets/img/map.png">
                    </div>
                </div>
            </div>
        </div>
        <foots  @navChange="clickNav"></foots>
    </div>
</template>
<script>
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'

    import {genZipUrl} from "@/utils/index"
    export default {
        name: 'contact',
        data() {
            return {
                dataForm: {},
                options: {
                    sList: [],
                    qList: []
                },
                ssq: {
                    code: ''
                },
                sCode: '',
                fgs: []

            }
        },
        components: {
            heads,
            foots
        },
        watch: {
            '$route': function (to, from) {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            }
        },
        mounted() {
            //获取省
            if (this.$route.params.type !== 1) {

            } else {
                document.getElementById("netWork").scrollIntoView();
            }

            if (this.$route.params.dataForm) {
                this.ssq.code = this.$route.params.dataForm.sCode
                this.getQList()
                this.sCode = this.$route.params.dataForm.qCode
                this.getFgs()
            }
            this.getSList()
            if (this.$route.params.type) {
                this.clickNav(this.$route.params.type)
            }else{
                window.scrollTo(0, 0);
            }
            

        },
        methods: {
            downloadBook() {
                this.$router.push("/Books")
            },
            clickNav(number) {
                if (number == 1) {
                    document.getElementById("zzz").scrollIntoView();
                }
                if (number == 2) {
                    document.getElementById("aaa").scrollIntoView();
                }
            },
            formSubmit() {
                this.dataForm.columnValue = 0
                this.dataForm.source = 'www.50bm.com'
                this.$http.post(`/attract_investment/front/show/postSiteMsg`, this.dataForm).then(res => {
                    if (res.code !== 0) {
                        return this.$message.error(res.msg)
                    }
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                })
            },
            //获取省
            getSList() {
                this.$http.get(`/attract_investment/front/show/getProvinceList`).then(({
                    data: res
                }) => {
                    this.options.sList = res
                })
            },
            //获取市
            getQList() {
                this.$http.get(`/attract_investment/front/show/getPlacetbPage`, {
                    params: {
                        parentid: this.ssq.code,
                        deep: 2
                    }
                }).then(({
                    data: res
                }) => {
                    this.options.qList = res.list
                })
            },
            //获取分公司
            getFgs() {
                this.$http.get(`/attract_investment/front/show/getAllCompanyInfoPage`, {
                    params: {
                        limit: 1000,
                        page: 1,
                        citycode: this.sCode
                    }
                }).then(({
                    data: res
                }) => {
                    this.fgs = res.list
                })
            },

        },
    }
</script>
<style lang="scss" scoped>
    .select_box {
        margin-bottom: 45px;
    }

    .all_box {
        width: 1200px;
        margin: 0 auto;
    }

    .contact_title {
        h3 {
            font-size: 36px;
            color: #454545;
            margin: 0;
            margin-bottom: 18px;
        }
    }

    .henxian {
        width: 45px;
        height: 5px;
        background: #d4b391;
        margin: 0 auto;
    }

    .contact_form_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 120px;
    }

    .contact_left {
        text-align: left;
        width: 45%;
        box-sizing: border-box;
        padding-right: 200px;

        p {
            font-size: 16px;
            color: #454545;
            line-height: 26px;
            margin: 40px 0;
        }
    }

    .contact_left p:first-child {
        margin-top: 0;
    }

    .contact_left_img_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .contact_right {
        width: 55%;
    }

    .contact_img_box_item {
        margin-right: 40px;
    }

    .contact_img_box_item:last-child {
        margin-right: 0;
    }

    .tijiao {
        width: 100%;
        background: #d4bb8e;
        color: #fff;
        border: none;
    }

    .banner {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .contact_box {
        padding-top: 135px;
        padding-bottom: 130px;
    }

    .netWork {
        margin-top: 140px;
    }

    .fgs_item_box {
        padding: 10px;
    }

    .fgs_item {
        border: 1px solid #cecece;
        text-align: left;
        height: 140px;
    }

    .fgs_title {
        padding: 9px 10px;
        font-size: 14px;
        background: #f7f8fa;
    }

    .fgs_body {
        font-size: 14px;
        padding: 9px 10px;
        box-sizing: border-box;

        p {
            margin: 0;
            line-height: 26px
        }
    }

    .map_box {
        margin-top: 110px;

        img {
            width: 100%;
        }
    }

    .netWork_title {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        img {
            width: 32px;
            height: 36px;
            margin-right: 10px;
        }

        h3 {
            color: #ab1621;
            font-size: 24px;
            margin: 0;

            span {
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
</style>
